"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStopwatchCheck = void 0;
var React = __importStar(require("react"));
var IconStopwatchCheck = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", width: "1em", height: "1em", viewBox: "0 0 48 52" }, props),
        React.createElement("path", { d: "M38.1868 13.4798L39.1181 12.5566C39.8011 12.9874 40.6083 12.9259 41.1672 12.3719C41.7881 11.7564 41.7881 10.71 41.1672 10.0945L38.8698 7.81708C38.2488 7.20156 37.1933 7.20156 36.5724 7.81708C36.0135 8.37105 35.8894 9.17122 36.3861 9.84829L35.3926 10.7716C32.288 8.3095 28.5004 6.6476 24.4024 6.15518V4.24708C25.3337 4.06242 26.1409 3.20069 26.1409 2.15431C26.1409 0.984829 25.1475 0 23.9677 0H19.6213C18.4416 0 17.4481 0.923277 17.4481 2.15431C17.4481 3.20069 18.2553 4.06242 19.1867 4.24708V6.21673C8.44478 7.50932 0.000294978 16.619 0.000294978 27.6983C-0.0617968 39.6394 9.68662 49.303 21.7324 49.303C33.7782 49.303 43.5266 39.6394 43.5266 27.6983C43.5266 22.2818 41.5397 17.2961 38.1868 13.4798ZM35.3305 41.1166C31.6671 44.7482 26.886 46.7178 21.7324 46.7178C16.5788 46.7178 11.7977 44.7482 8.13432 41.1166C4.533 37.5466 2.48397 32.7456 2.48397 27.6368C2.48397 22.528 4.4709 17.7885 8.13432 14.1569C11.7977 10.6485 16.6409 8.67881 21.7324 8.67881C26.824 8.67881 31.6671 10.6485 35.3305 14.28C38.9319 17.85 40.9809 22.6511 40.9809 27.7599C40.9188 32.7456 38.9319 37.5466 35.3305 41.1166Z" }),
        React.createElement("path", { d: "M7.76181 27.5756C7.76181 27.0832 7.38926 26.7139 6.89252 26.7139H5.15395C4.65722 26.7139 4.28467 27.0832 4.28467 27.5756C4.28467 28.068 4.65722 28.4373 5.15395 28.4373H6.89252C7.38926 28.4373 7.76181 28.068 7.76181 27.5756Z" }),
        React.createElement("path", { d: "M21.7322 13.7882C22.2289 13.7882 22.6636 13.4189 22.6636 12.9265V11.203C22.6636 10.7106 22.2289 10.3413 21.7322 10.3413C21.2354 10.3413 20.8008 10.7106 20.8008 11.203V12.9265C20.8008 13.4189 21.2354 13.7882 21.7322 13.7882Z" }),
        React.createElement("path", { d: "M38.3107 26.7139H36.5722C36.0754 26.7139 35.7029 27.0832 35.7029 27.5756C35.7029 28.068 36.0754 28.4373 36.5722 28.4373H38.3107C38.8075 28.4373 39.18 28.068 39.18 27.5756C39.18 27.1447 38.8075 26.7139 38.3107 26.7139Z" }),
        React.createElement("path", { d: "M21.7322 41.4854C21.2354 41.4854 20.8008 41.8547 20.8008 42.3471V44.0705C20.8008 44.5629 21.2354 44.9322 21.7322 44.9322C22.2289 44.9322 22.6636 44.5629 22.6636 44.0705V42.3471C22.6636 41.8547 22.2289 41.4854 21.7322 41.4854Z" }),
        React.createElement("path", { d: "M8.44483 34.2842L6.95462 35.1459C6.51998 35.3921 6.3958 35.8845 6.64416 36.3154C6.89253 36.7462 7.38927 36.8693 7.82391 36.6231L9.31411 35.7614C9.74876 35.5152 9.87294 35.0228 9.62457 34.5919C9.4383 34.1611 8.87947 34.038 8.44483 34.2842Z" }),
        React.createElement("path", { d: "M14.2194 12.9879C13.971 12.5571 13.4742 12.434 13.0396 12.6802C12.6049 12.9264 12.4808 13.4188 12.7291 13.8497L13.5984 15.3269C13.8468 15.7578 14.3435 15.8809 14.7782 15.6347C15.2128 15.3885 15.337 14.896 15.0887 14.4652L14.2194 12.9879Z" }),
        React.createElement("path", { d: "M35.0202 21.0514L36.5104 20.1896C36.945 19.9434 37.0692 19.451 36.8209 19.0202C36.5725 18.5893 36.0758 18.4662 35.6411 18.7124L34.1509 19.5741C33.7163 19.8203 33.5921 20.3127 33.8405 20.7436C34.0888 21.1745 34.5856 21.2976 35.0202 21.0514Z" }),
        React.createElement("path", { d: "M29.9286 40.0089C29.6802 39.5781 29.1835 39.455 28.7488 39.7012C28.3142 39.9474 28.19 40.4398 28.4384 40.8707L29.3076 42.3479C29.556 42.7788 30.0527 42.9019 30.4874 42.6557C30.922 42.4095 31.0462 41.917 30.7979 41.4862L29.9286 40.0089Z" }),
        React.createElement("path", { d: "M14.7782 39.6396C14.3435 39.3934 13.8468 39.5165 13.5984 39.9474L12.7291 41.4246C12.4808 41.8555 12.6049 42.3479 13.0396 42.5941C13.4742 42.8403 13.971 42.7172 14.2194 42.2864L15.0887 40.8091C15.337 40.4398 15.2128 39.8859 14.7782 39.6396Z" }),
        React.createElement("path", { d: "M9.31411 19.5741L7.82391 18.7124C7.38927 18.4662 6.89253 18.5893 6.64416 19.0202C6.3958 19.451 6.51998 19.9434 6.95462 20.1896L8.44483 21.0514C8.87947 21.2976 9.37621 21.1745 9.62457 20.7436C9.87294 20.3743 9.74876 19.8203 9.31411 19.5741Z" }),
        React.createElement("path", { d: "M30.4874 12.6802C30.0528 12.434 29.556 12.5571 29.3077 12.9879L28.4384 14.4652C28.19 14.896 28.3142 15.3885 28.7488 15.6347C29.1835 15.8809 29.6802 15.7578 29.9286 15.3269L30.7979 13.8497C31.0463 13.4803 30.86 12.9264 30.4874 12.6802Z" }),
        React.createElement("path", { d: "M36.5104 35.1459L35.0202 34.2842C34.5856 34.038 34.0888 34.1611 33.8405 34.5919C33.5921 35.0228 33.7163 35.5152 34.1509 35.7614L35.6411 36.6231C36.0758 36.8693 36.5725 36.7462 36.8209 36.3154C37.0692 35.9461 36.945 35.3921 36.5104 35.1459Z" }),
        React.createElement("path", { d: "M27.4448 17.9732C27.2585 17.8501 26.9481 17.9117 26.8239 18.0963L22.1049 25.4826C21.9807 25.4826 21.8566 25.421 21.6703 25.421C20.4905 25.421 19.4971 26.4058 19.4971 27.5753C19.4971 28.7448 20.4905 29.7296 21.6703 29.7296C22.85 29.7296 23.8435 28.7448 23.8435 27.5753C23.8435 27.0829 23.6572 26.652 23.4089 26.2827L27.569 18.4656C27.7553 18.4041 27.6932 18.0963 27.4448 17.9732Z" }),
        React.createElement("circle", { cx: "37.12", cy: "41.1691", r: "10.1237", fill: "#00D896" }),
        React.createElement("path", { d: "M42.1785 38.3817C41.9073 38.1072 41.4953 38.1072 41.2189 38.3816L35.9565 43.7066L33.2914 41.0098C33.0202 40.7354 32.6082 40.7354 32.3318 41.0098C32.0606 41.2842 32.0606 41.7012 32.3318 41.9809L35.4767 45.1631C35.6123 45.3004 35.7479 45.369 35.9565 45.369C36.1599 45.369 36.3007 45.3004 36.4363 45.1631L42.1785 39.3527C42.4497 39.0783 42.4497 38.6614 42.1785 38.3817Z", fill: "white" })));
};
exports.IconStopwatchCheck = IconStopwatchCheck;
