"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconChat = void 0;
var React = __importStar(require("react"));
var IconChat = function (props) {
    return (React.createElement("svg", __assign({ width: '1.1em', height: '1em', viewBox: "0 0 21 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M3.673 18c-.073 0-.22 0-.293-.07-.294-.071-.441-.354-.441-.636v-2.47h-.735C.955 14.823 0 13.905 0 12.705v-1.412c0-.423.294-.706.735-.706.44 0 .734.283.734.706v1.412c0 .423.294.706.735.706h1.47c.44 0 .734.282.734.706v1.623l2.425-2.117a.68.68 0 0 1 .514-.212h7.347c.44 0 .735-.283.735-.706V5.647c0-.423-.294-.706-.735-.706H2.204c-.44 0-.735.283-.735.706V7.06c0 .423-.293.706-.734.706S0 7.482 0 7.059V5.647c0-1.2.955-2.118 2.204-2.118h12.49c1.249 0 2.204.918 2.204 2.118v7.059c0 1.2-.955 2.117-2.204 2.117H7.567L4.114 17.86c-.073.07-.22.141-.44.141Zm1.47-15.882c0-.424.294-.706.735-.706h5.877c.44 0 .735-.283.735-.706 0-.424-.294-.706-.735-.706H5.878c-1.25 0-2.205.918-2.205 2.118 0 .423.294.706.735.706s.735-.283.735-.706ZM20.57 9.176V2.118c0-1.2-.955-2.118-2.204-2.118h-2.204c-.44 0-.734.282-.734.706 0 .423.293.706.734.706h2.204c.441 0 .735.282.735.706v7.058c0 .424-.294.706-.735.706-.44 0-.734.283-.734.706 0 .424.294.706.734.706 1.25 0 2.204-.918 2.204-2.118ZM13.96 7.765c0-.424-.294-.706-.734-.706H3.673c-.44 0-.734.282-.734.706 0 .423.294.706.734.706h9.552c.44 0 .734-.283.734-.706Zm0 2.823c0-.423-.294-.706-.734-.706H3.673c-.44 0-.734.283-.734.706 0 .424.294.706.734.706h9.552c.44 0 .734-.282.734-.706ZM.735 8.471c-.441 0-.735.282-.735.705 0 .424.294.706.735.706.44 0 .734-.282.734-.706 0-.423-.293-.705-.734-.705ZM13.959 0c-.44 0-.734.282-.734.706 0 .423.293.706.734.706s.735-.283.735-.706c0-.424-.294-.706-.735-.706Z" })));
};
exports.IconChat = IconChat;
