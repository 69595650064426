import { Account, SourceFlow, TransportRequest } from '@brenger/api-client';
import { getIdFromIri } from '@brenger/utils';
import { push } from 'connected-react-router';
import { all, call, put, select, take } from 'typed-redux-saga';
import { hotjar } from '../../../configs/hotjar';
import { RootState } from '../../../typings';
import { logError, logException } from '../../../utils/basics';
import { trackEventPurchase } from '../../../utils/eventTracking';
import { translate } from '../../../utils/localization';
import { coreClient } from '../../../utils/request';
import { getTermsFormValues } from '../containers/Terms';
import {
  actions,
  getCustomerDetails,
  getIsDirectlyPayable,
  getPriceAmount,
  getProductPaymentOptIn,
  getTransportRequestCreationParams,
  validateTransportRequest,
} from '../ducks';
import { GeneralFlowActionTypes, TransportRequestValidationError } from '../typings';
import { r } from '../../../routes';
import { getLoggedInUser } from '../../User/ducks';

const SES_STORAGE_TR_ATTEMPTS = 'UPLOAD_TR_ATTEMPS';

function* monitorUploadAttempts(error: TransportRequestValidationError): Generator {
  const attempts = Number(sessionStorage.getItem(SES_STORAGE_TR_ATTEMPTS)) + 1;
  sessionStorage.setItem(SES_STORAGE_TR_ATTEMPTS, String(attempts));
  if (attempts < 2) {
    return;
  }
  // At second attempt let's log it to sentry
  const state = yield* select((s: RootState) => {
    return { tr: s.generalTransport, form: s.form };
  });
  const params = yield* select(getTransportRequestCreationParams);
  logException(`2nd TR upload attempt: FED validation error ${error}`, { state, params });
}

export function* createTr(): Generator {
  /**
   * Since we live by laws of pricing, first thing we do is getting a fresh quote in.
   */
  // Call for pricing
  yield* put(actions.getPrice());
  // Wait till fresh result or something went wrong
  const nextPriceEvent = yield* take([GeneralFlowActionTypes.SET_QUOTE, GeneralFlowActionTypes.RESET_PRICE]);
  // If the price gets reset after getting a new price, something isn't right.
  // It's best to show the user an error, and ask to start over.
  if (nextPriceEvent.type === GeneralFlowActionTypes.RESET_PRICE) {
    yield* call(monitorUploadAttempts, 'failed_price_request');
    yield* put(actions.setTrValidationError('failed_price_request'));
    return;
  }

  /**
   * Validate earlier choices against fresh pricing result
   */
  const validationError = yield* select(validateTransportRequest);
  if (validationError) {
    yield* call(monitorUploadAttempts, validationError);
    yield* put(actions.setTrValidationError(validationError));
    return;
  }

  /**
   * We are good!
   */
  // so remove TR attempts from storage
  sessionStorage.removeItem(SES_STORAGE_TR_ATTEMPTS);
  // get TR params
  const transportRequestCreationParams = yield* select(getTransportRequestCreationParams);

  try {
    yield* put(actions.toggleSubmitting(true));
    hotjar.fireEvent('transport_request_call');
    const transportRequest = yield* call(coreClient.transportRequests.create, transportRequestCreationParams);
    // save uuid and navigate user to payment
    if (transportRequest) {
      hotjar.fireEvent('transport_request_success');
      const uuid = getIdFromIri(transportRequest['@id']);
      const effects: Generator[] = [];
      effects.push(call(subscribeNewsletter));
      /** inform big brother */
      const price = yield* select(getPriceAmount);
      trackEventPurchase(uuid || null, SourceFlow.GENERAL, (price / 100).toFixed(2));
      yield* all(effects);
      /**
       * Where to go next
       * - If core supplies us with a redirect url, we should follow
       * - If product payment, send them to payment
       * - If not guaranteed, send them to the thank you page
       */
      if (transportRequest.payment_redirect_url) {
        window.location.assign(transportRequest.payment_redirect_url);
      } else {
        yield* call(determineNonDirectPaymentAction, transportRequest);
      }
    } else {
      yield* put(actions.toggleSubmitting(false));
    }
  } catch (err) {
    logError(err);
    hotjar.fireEvent('transport_request_failure');
    yield* put(actions.toggleSheet());
    if (err.response) {
      if (err.response.data['hydra:description']) {
        const msg = err.response.data['hydra:description'].replace(/[\r\n]/g, '<br />');
        yield* all([put(actions.toggleSubmitting(false)), put(actions.failedTransportRequest(msg))]);
        logException(`UPLOAD TR FAILED - Validation: ${err.response.data['hydra:description']}`, {
          error: err.response.data['hydra:description'],
          payload: transportRequestCreationParams,
        });
      }
    } else {
      logException(`UPLOAD TR FAILED - Crash: ${err}`, {
        error: err,
        payload: transportRequestCreationParams,
      });
      const msg = translate('request_flow.errors.handle_request');
      yield* all([put(actions.toggleSubmitting(false)), put(actions.failedTransportRequest(msg))]);
    }

    openChatCustomerCare();
  }
}

function* determineNonDirectPaymentAction(tr: TransportRequest): Generator {
  const user = yield* select(getLoggedInUser);
  const uuid = getIdFromIri(tr['@id']);
  // directPayable is in line with guaranteed with a few exceptions, value supplied by pricing
  const directPayable = yield* select(getIsDirectlyPayable);
  if (!directPayable) {
    yield* put(push(r.payment.thankYou({ id: uuid, type: 'not_guaranteed' })));
    return;
  }
  const isProductPayment = yield* select(getProductPaymentOptIn);
  if (isProductPayment) {
    yield* put(push(r.generalFlow.oppPayment({ id: uuid })));
    return;
  }
  const isPayingByInvoice = (user.userData?.account as Account | undefined)?.paying_by_invoice;
  /**
   * So in the first place we really want to determine it ourselfs if the client is allowed to pay by invoice.
   * But! Somehow in very few cases our logged in state is out of sync, frontend state says logged out while core still thinks the user is logged in
   * So, we fall back to the field `needs_payment`, if it is falls we can proceed to the business thank you page.
   */
  if (isPayingByInvoice || !tr.needs_payment) {
    yield* put(push(r.payment.thankYou({ id: uuid, type: 'business' })));
    return;
  }
  /**
   * If we end up here we have a problem
   */
  logException('TR UPLOADED without payment action', { tr, user });
}

function* subscribeNewsletter(): Generator {
  const termsForm = yield* select(getTermsFormValues);
  if (!termsForm?.conditions?.newsletter_conditions) {
    return;
  }
  const customerDetails = yield* select(getCustomerDetails);
  try {
    yield* call(coreClient.newsletter.create, {
      first_name: customerDetails.first_name as string,
      last_name: customerDetails.last_name as string,
      email: customerDetails.email as string,
    });
  } catch (e) {
    /* Let this fail silent, it is bad luck, but not a show stopper  */
    logError(e);
  }
}

function openChatCustomerCare(): void {
  const body = document.body;
  const timing = body.classList.contains('chat--is-visible') ? 0 : 350;
  if (!body.classList.contains('chat--is-visible')) {
    body.classList.add('chat--is-visible');
    if (window.fcWidget && typeof window.fcWidget.open !== 'undefined') {
      setTimeout(() => {
        window.fcWidget.open();
      }, timing);
    }
  } else {
    body.classList.remove('chat--is-visible');
  }
}
