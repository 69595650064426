import React from 'react';
import '../../../../assets/product-selection/itemConfig.scss';
import { useIntersection } from '../../../../hooks';
import { trackEvent } from '../../../../utils/eventTracking';

interface Props {
  listPosition: number;
  listLength: number;
  query: string;
  id: string;
}

export const SearchListItemWrapper: React.FC<Props> = ({ children, ...eventData }) => {
  const intersectionRef = React.useRef(null);
  const isIntersecting = useIntersection(intersectionRef);
  const [eventDispatched, setEventDispatched] = React.useState(false);

  React.useEffect(() => {
    if (isIntersecting && !eventDispatched) {
      trackEvent({
        event: 'productSelection',
        action: 'impression',
        ...eventData,
      });

      setEventDispatched(true);
    }
  }, [isIntersecting, eventDispatched]);

  return <div ref={intersectionRef}>{children}</div>;
};
