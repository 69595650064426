import * as React from 'react';
import { logException } from '../../../../utils/basics';

import { ErrorPage } from './ErrorPage';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<React.ComponentClass, State> {
  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props: React.ComponentClass) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error to Sentry.
    logException(error.message, errorInfo as unknown as Record<string, unknown>);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // Render custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
