declare global {
  interface Window {
    hj?(action: string, data: string | string[]): void;
  }
}

export const hotjar = {
  fireEvent: (events: string) => {
    if (window.hj && typeof window.hj === 'function') {
      window.hj('event', events);
    }
  },
  addForm: (key: string | string[]) => {
    if (typeof window.hj === 'function') {
      window.hj('trigger', key);
    }
  },
};
