"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconHeart = void 0;
var React = __importStar(require("react"));
var IconHeart = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", height: "1em", width: "1em", viewBox: "0 0 22 21" }, props),
        React.createElement("path", { d: "M3.06972 11.6673C2.93405 11.5316 2.73055 11.4977 2.59489 11.6334C2.45922 11.769 2.42531 11.9725 2.56097 12.1082L3.13755 12.7526C3.20538 12.8204 3.40888 12.9222 3.61237 12.7865C3.78195 12.6848 3.78195 12.4473 3.64629 12.3117L3.06972 11.6673Z" }),
        React.createElement("path", { d: "M19.3836 1.93322C18.2983 0.678322 16.8399 0 15.2795 0C15.076 0 14.9404 0.135664 14.9404 0.339161C14.9404 0.542658 15.076 0.678322 15.2795 0.678322C16.6362 0.678322 17.8911 1.28881 18.8747 2.37413C20.8757 4.64651 20.8757 8.37747 18.8747 10.6838L10.5313 20.1803L4.73182 13.5327C4.59616 13.3971 4.39266 13.3631 4.257 13.4988C4.12133 13.6345 4.08742 13.838 4.22308 13.9736L10.2939 20.8925C10.5313 21.096 10.7348 20.9604 10.8026 20.8925L19.3836 11.0906C21.5881 8.58078 21.5881 4.47712 19.3836 1.93322Z" }),
        React.createElement("path", { d: "M2.18798 2.37413C3.17154 1.28881 4.42644 0.678322 5.78309 0.678322C7.13973 0.678322 8.39463 1.28881 9.37819 2.37413L10.2939 3.42553C10.5653 3.62902 10.7348 3.49336 10.8027 3.42553L11.6845 2.37413C12.3289 1.66189 13.109 1.11923 13.9569 0.881819C14.1264 0.813987 14.2282 0.644406 14.1943 0.474826C14.1264 0.305245 13.9569 0.203497 13.7873 0.237413C12.8037 0.542658 11.9219 1.11923 11.1757 1.93322L10.5313 2.67937L9.88694 1.93322C8.80162 0.678322 7.34323 0 5.78289 0C4.22256 0 2.76436 0.678322 1.67885 1.93322C-0.559616 4.47693 -0.559616 8.58078 1.67885 11.1243C1.74668 11.1921 1.95018 11.2939 2.15367 11.1582C2.32325 11.0565 2.32325 10.819 2.18759 10.6834C0.186539 8.37709 0.186539 4.68004 2.18759 2.37374L2.18798 2.37413Z" }),
        React.createElement("path", { d: "M18.6369 7.46153C18.4673 7.42761 18.2638 7.56328 18.2299 7.73286C18.196 7.97027 18.1281 8.17377 18.0603 8.37727C17.9925 8.54685 18.0942 8.75034 18.2638 8.81818C18.4334 8.85209 18.6369 8.75034 18.7047 8.61468C18.7725 8.37727 18.8404 8.13985 18.8743 7.90244C18.9421 7.66503 18.8404 7.49545 18.6369 7.46153Z" }),
        React.createElement("path", { d: "M17.4843 2.98468C17.3486 2.84902 17.1451 2.84902 17.0095 2.98468C16.8738 3.12035 16.8738 3.32384 17.0095 3.45951C17.7895 4.2735 18.2304 5.35881 18.3322 6.47804C18.3322 6.64762 18.5018 6.78329 18.6714 6.78329C18.8409 6.78329 19.0105 6.61371 19.0105 6.41021C18.9088 5.1214 18.3661 3.90042 17.4843 2.98468Z" })));
};
exports.IconHeart = IconHeart;
