"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStopwatch = void 0;
var React = __importStar(require("react"));
var IconStopwatch = function (props) {
    return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 13 15", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M10.9116 3.83016L11.1763 3.56798C11.3705 3.69033 11.5999 3.67285 11.7588 3.51555C11.9353 3.34077 11.9353 3.04364 11.7588 2.86886L11.1057 2.22217C10.9292 2.04739 10.6292 2.04739 10.4527 2.22217C10.2938 2.37947 10.2585 2.60669 10.3997 2.79895L10.1173 3.06112C9.2348 2.36199 8.15814 1.89008 6.99323 1.75026V1.20843C7.25798 1.156 7.48743 0.911305 7.48743 0.614177C7.48743 0.282092 7.20503 0.00244141 6.86968 0.00244141H5.63417C5.29882 0.00244141 5.01641 0.264614 5.01641 0.614177C5.01641 0.911305 5.24587 1.156 5.51062 1.20843V1.76773C2.45714 2.13478 0.0567245 4.72154 0.0567245 7.86761C0.0390743 11.2584 2.81015 14.0024 6.23427 14.0024C9.6584 14.0024 12.4295 11.2584 12.4295 7.86761C12.4295 6.32953 11.8647 4.9138 10.9116 3.83016ZM10.0997 11.6778C9.0583 12.7091 7.69924 13.2684 6.23427 13.2684C4.76931 13.2684 3.41025 12.7091 2.36889 11.6778C1.34518 10.6641 0.76273 9.30082 0.76273 7.85013C0.76273 6.39945 1.32753 5.05363 2.36889 4.02242C3.41025 3.02616 4.78696 2.46686 6.23427 2.46686C7.68159 2.46686 9.0583 3.02616 10.0997 4.05737C11.1234 5.07111 11.7058 6.4344 11.7058 7.88509C11.6882 9.30082 11.1234 10.6641 10.0997 11.6778Z" }),
        React.createElement("path", { d: "M2.26331 7.83258C2.26331 7.69276 2.15741 7.58789 2.01621 7.58789H1.522C1.3808 7.58789 1.2749 7.69276 1.2749 7.83258C1.2749 7.97239 1.3808 8.07726 1.522 8.07726H2.01621C2.15741 8.07726 2.26331 7.97239 2.26331 7.83258Z" }),
        React.createElement("path", { d: "M6.23448 3.91774C6.37568 3.91774 6.49922 3.81287 6.49922 3.67304V3.18366C6.49922 3.04383 6.37568 2.93896 6.23448 2.93896C6.09328 2.93896 5.96973 3.04383 5.96973 3.18366V3.67304C5.96973 3.81287 6.09328 3.91774 6.23448 3.91774Z" }),
        React.createElement("path", { d: "M10.9469 7.58789H10.4527C10.3115 7.58789 10.2056 7.69276 10.2056 7.83258C10.2056 7.97239 10.3115 8.07726 10.4527 8.07726H10.9469C11.0881 8.07726 11.194 7.97239 11.194 7.83258C11.194 7.71023 11.0881 7.58789 10.9469 7.58789Z" }),
        React.createElement("path", { d: "M6.23448 11.7827C6.09328 11.7827 5.96973 11.8876 5.96973 12.0274V12.5168C5.96973 12.6566 6.09328 12.7615 6.23448 12.7615C6.37568 12.7615 6.49922 12.6566 6.49922 12.5168V12.0274C6.49922 11.8876 6.37568 11.7827 6.23448 11.7827Z" }),
        React.createElement("path", { d: "M2.45701 9.73778L2.03341 9.98247C1.90986 10.0524 1.87456 10.1922 1.94516 10.3146C2.01576 10.4369 2.15696 10.4719 2.28051 10.4019L2.70411 10.1572C2.82766 10.0873 2.86296 9.94751 2.79236 9.82517C2.73941 9.70282 2.58056 9.66787 2.45701 9.73778Z" }),
        React.createElement("path", { d: "M4.09875 3.6904C4.02815 3.56806 3.88695 3.5331 3.76339 3.60301C3.63984 3.67293 3.60454 3.81275 3.67514 3.9351L3.92225 4.35457C3.99285 4.47691 4.13405 4.51187 4.2576 4.44196C4.38115 4.37204 4.41645 4.23222 4.34585 4.10988L4.09875 3.6904Z" }),
        React.createElement("path", { d: "M10.0115 5.98008L10.4351 5.73539C10.5586 5.66547 10.5939 5.52565 10.5233 5.4033C10.4527 5.28095 10.3115 5.24599 10.188 5.31591L9.76437 5.5606C9.64082 5.63052 9.60552 5.77034 9.67612 5.89269C9.74672 6.01504 9.88792 6.04999 10.0115 5.98008Z" }),
        React.createElement("path", { d: "M8.56408 11.3633C8.49348 11.2409 8.35228 11.206 8.22873 11.2759C8.10517 11.3458 8.06987 11.4856 8.14048 11.608L8.38758 12.0274C8.45818 12.1498 8.59938 12.1847 8.72293 12.1148C8.84648 12.0449 8.88179 11.9051 8.81118 11.7827L8.56408 11.3633Z" }),
        React.createElement("path", { d: "M4.2576 11.2583C4.13405 11.1884 3.99285 11.2233 3.92225 11.3457L3.67514 11.7651C3.60454 11.8875 3.63984 12.0273 3.76339 12.0972C3.88695 12.1671 4.02815 12.1322 4.09875 12.0098L4.34585 11.5904C4.41645 11.4855 4.38115 11.3282 4.2576 11.2583Z" }),
        React.createElement("path", { d: "M2.70411 5.5606L2.28051 5.31591C2.15696 5.24599 2.01576 5.28095 1.94516 5.4033C1.87456 5.52565 1.90986 5.66547 2.03341 5.73539L2.45701 5.98008C2.58056 6.04999 2.72176 6.01504 2.79236 5.89269C2.86296 5.78782 2.82766 5.63052 2.70411 5.5606Z" }),
        React.createElement("path", { d: "M8.72293 3.60301C8.59938 3.5331 8.45818 3.56806 8.38758 3.6904L8.14048 4.10988C8.06987 4.23222 8.10517 4.37204 8.22873 4.44196C8.35228 4.51187 8.49348 4.47691 8.56408 4.35457L8.81119 3.9351C8.88179 3.83023 8.82884 3.67293 8.72293 3.60301Z" }),
        React.createElement("path", { d: "M10.4351 9.98247L10.0115 9.73778C9.88792 9.66787 9.74672 9.70282 9.67612 9.82517C9.60552 9.94751 9.64082 10.0873 9.76437 10.1572L10.188 10.4019C10.3115 10.4719 10.4527 10.4369 10.5233 10.3146C10.5939 10.2097 10.5586 10.0524 10.4351 9.98247Z" }),
        React.createElement("path", { d: "M7.85785 5.10608C7.8049 5.07112 7.71665 5.0886 7.68135 5.14103L6.33994 7.23842C6.30464 7.23842 6.26934 7.22094 6.21639 7.22094C5.88104 7.22094 5.59863 7.50059 5.59863 7.83268C5.59863 8.16477 5.88104 8.44442 6.21639 8.44442C6.55174 8.44442 6.83414 8.16477 6.83414 7.83268C6.83414 7.69285 6.78119 7.57051 6.71059 7.46564L7.89315 5.2459C7.9461 5.22843 7.92845 5.14103 7.85785 5.10608Z" })));
};
exports.IconStopwatch = IconStopwatch;
