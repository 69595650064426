/*
/* BRENGER CLIENT GLOBAL CONSTANTS
/* ================================================== */

import { Config } from '../config';
import { ACTIVE_LANG } from './localization';

declare const BRENGER_TR_FLOW: string | undefined;

export const getBasePath = (): string => {
  /**
   * Returns the base url of our app
   * - First: looks for a global set at the page where the react app loads
   * - Second: returns our standard language prefixed path.
   */
  if (typeof BRENGER_TR_FLOW === 'string') {
    try {
      const url = new URL(BRENGER_TR_FLOW); // URL constructor is polyfilled for IE users
      return `${url.pathname}/${ACTIVE_LANG}/customer`;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  return `/${ACTIVE_LANG}/customer`;
};

export const BASE_PATH = getBasePath();

export const ACCEPT_HEADER = 'application/ld+json';
export const BASE_MAPS_URI = 'https://maps.googleapis.com/maps/api/staticmap';
export const TRUST_PILOT_REVIEW_URL = 'https://nl.trustpilot.com/evaluate/brenger.nl';
export const GOOGLE_REVIEW_URL = 'https://search.google.com/local/writereview?placeid=ChIJDxcpbp4JxkcRY_DecNr1mb8';
export const GOOGLE_MAP_KEY = 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ';
export const INSURANCE_AMOUNT = 50000;
export const DIFFERENT_DELIVERY_DATE_AMOUNT = 1000; // 10 euro in cents
export const DEFAULT_TIME_ZONE = 'Europe/Amsterdam';
export const LONG_DISTANCE_IN_KM = 150;
export const LONG_DISTANCE_IN_KM_EXTRA_DATE = 250;
export const MAX_ITEM_COUNT_FOR_FLOOR_LEVEL_SERVICE = 20;

export const ASSETS_PATH = (): string => {
  // Returns the path where images/pdf's are stored
  switch (Config.NODE_ENV) {
    case 'development':
      return Config.API_URL + '/user_data';
    case 'staging':
      return Config.API_URL + '/user_data';
    default:
      return 'https://user-data.brenger.nl';
  }
};

export const UPLOAD_HEADERS = {
  contentType: 'multipart/form-data',
  accept: ACCEPT_HEADER,
};

// time
export const TIME_SLOT_LENGTH = 4;
// normal time
export const TIME_START_MIN = 8;
export const TIME_END_MAX = 22;

export const GENERAL_DEFAULT_TIME_START = 8;
export const GENERAL_DEFAULT_TIME_END = 18;

// Max weight
export const MAX_WEIGHT = 200;

// item set sizes
export const BUSINESS_MAX_ITEM_SET_SIZE = 4;

// persist
export const PERSIST_VERSION = 9.1;

export const PERSIST_BLACKLIST = [
  'localize',
  'router',
  'chat',
  'statusPage',
  'base',
  // user has its own persisted reducer.
  'user',
];

// User assets
export const ROLES = {
  role_user: 'ROLE_USER',
  role_customer: 'ROLE_CUSTOMER',
  role_driver: 'ROLE_DRIVER',
  role_driver_pro: 'ROLE_DRIVER_PRO',
  role_administrator: 'ROLE_ADMINISTRATOR',
  role_customer_care: 'ROLE_CUSTOMER_CARE',
  role_needs_social_registration: 'ROLE_NEEDS_SOCIAL_REGISTRATION',
};

export const dummyItem = {
  title: '',
  length: 1,
  width: 1,
  height: 1,
  weight: 1,
  count: 1,
};

export const dummyItemSet = {
  title: '',
  description: '',
  items: [dummyItem],
};

// Extra matches that don't need confirmation
export const EXTRA_CARE_NO_CONFIRMATION = ['none', 'scooter', 'fridge'];

export const PRODUCT_PAYMENT_MAX_AMOUNT = 1000_00;

export const ANIMATION_CHOICE_DELAY = 250;

export const delay = (ms = ANIMATION_CHOICE_DELAY): Promise<void> => new Promise(res => setTimeout(res, ms));
