"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontLoader = void 0;
var fontLoader = function () {
    // Hey I got some really cool fonts, wanna try?
    if (window.matchMedia && window.matchMedia('(prefers-reduced-data: reduce)').matches) {
        // Party pooper mode engaged
        return;
    }
    // The styles should only added once
    if (document.getElementById('brenger-extra-fonts')) {
        return;
    }
    /**
     * Please note the "_sub" suffix of the font filename, we are loading a Latin subset
     * Original font files are included in this project. See /fonts
     * Sub sets created with https://transfonter.org/
     * */
    var fontUrl = "https://www.brenger.nl/assets/fonts";
    var styles = ("\n  @font-face {\n    font-family: 'bariol';\n    src: url('" + fontUrl + "/bariol_regular_italic-webfont_sub.woff2') format('woff2'),\n      url('" + fontUrl + "/bariol_regular_italic-webfont_sub.woff') format('woff');\n    font-weight: normal;\n    font-style: italic;\n    font-display: swap;\n  }\n\n  @font-face {\n    font-family: 'bariol';\n    src: url('" + fontUrl + "/bariol_bold-webfont_sub.woff2') format('woff2'),\n      url('" + fontUrl + "/bariol_bold-webfont_sub.woff') format('woff');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n  }\n\n  @font-face {\n    font-family: 'bariol';\n    src: url('" + fontUrl + "/bariol_bold_italic-webfont_sub.woff2') format('woff2'),\n      url('" + fontUrl + "/bariol_bold_italic-webfont_sub.woff') format('woff');\n    font-weight: bold;\n    font-style: italic;\n    font-display: swap;\n  }\n  ")
        // This removes line breaks, so we can write readable CSS above
        .replace(/(\r\n|\n|\r)/gm, '');
    var styleSheet = document.createElement('style');
    styleSheet.id = 'brenger-extra-fonts';
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
};
exports.fontLoader = fontLoader;
