"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconEye = void 0;
var React = __importStar(require("react"));
var IconEye = function (props) {
    return (React.createElement("svg", __assign({ width: "24px", height: "14px", viewBox: "0 0 24 14" }, props),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-556.000000, -18.000000)" },
                React.createElement("g", null,
                    React.createElement("path", { fill: "currentColor", d: "M556.172045,25.4531183 C555.938393,25.2008172 555.943352,24.8052043 556.183129,24.5586237 C557.678092,23.0222366 562.952611,18 568.017689,18 C573.082768,18 578.331034,23.0225376 579.818121,24.5589247 C580.056732,24.8052043 580.061107,25.199914 579.828039,25.4516129 C578.392875,27.0003441 573.345882,32 567.999312,32 C562.654493,32 557.608667,27.0030538 556.172045,25.4531183 Z M572.267788,25.0000301 C572.267788,22.5679441 570.357444,20.5962022 568.001092,20.5962022 C565.644448,20.5962022 563.734396,22.5679441 563.734396,25.0000301 C563.734396,27.4321161 565.644448,29.4038581 568.001092,29.4038581 C570.357444,29.4038581 572.267788,27.4321161 572.267788,25.0000301 Z" }))))));
};
exports.IconEye = IconEye;
