import { CoordsPrecisionLevel, GeoAutocomplete, SourceFlow, TransportRequestCreationParams } from '@brenger/api-client';
import { getIdFromIri } from '@brenger/utils';
import { AxiosError } from 'axios';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { addDays } from 'date-fns';
import _get from 'lodash/get';
import { matchPath } from 'react-router';
import {
  change,
  clearFields,
  getFormSyncErrors,
  actionTypes as reduxFormTypes,
  reset,
  updateSyncErrors,
} from 'redux-form';
import { all, call, debounce, delay, fork, put, select, takeEvery, takeLatest, takeLeading } from 'typed-redux-saga';
import { Config } from '../../config';
import { hotjar } from '../../configs/hotjar';
import { r } from '../../routes';
import { QuoteRejectedReasonsGlobal, QuotesErrorTitles, ReduxFormInterAction, RootState } from '../../typings';
import * as appUtils from '../../utils/basics';
import { formatDate } from '../../utils/datetime';
import { trackEventPurchase } from '../../utils/eventTracking';
import { BUSINESS_MAX_ITEM_SET_SIZE } from '../../utils/global';
import {
  coreClient,
  paginationControls,
  priceClient,
  routePlannerClient,
  routePlannerClientToken,
} from '../../utils/request';
import { getLoggedInUser } from '../User/ducks';
import { UserActionTypes } from '../User/typings';
import { getBusinessDestinationFormValues } from './containers/Destination';
import {
  actions as businessActions,
  defaultState,
  getItemSetTotalSize,
  getNewTransportRequestLink,
  getPriceRequestParams,
  getSelectedDepot,
  getTransportRequestPayload,
} from './ducks';
import {
  BusinessFields,
  BusinessForms,
  QuoteRejectedReasonsBusiness,
  SetDateTimePeriodStartFromAction,
} from './interface';
import { BusinessActionTypes } from './typings';

function* determineBusinessDomain(action): Generator {
  const hasBusinessFlow = _get(action, 'payload.account.has_business_flow', false);
  yield* put(businessActions.setHasBusinessFlow(hasBusinessFlow));
  if (hasBusinessFlow) {
    yield* put(businessActions.setBusinessDomainName(action.payload.account.name));
    return;
  }
  yield* put(businessActions.setBusinessDomainName(defaultState.business_name));
}

function* fetchAddresses(): Generator {
  try {
    const loggedInUser = yield* select(getLoggedInUser);

    const depotAddressesResponses = yield* call(() =>
      paginationControls.getAllPages(coreClient.addresses.listDepotAddresses)
    );

    if (depotAddressesResponses.length === 0) {
      throw new Error('Either all api calls failed or this account has no depot address');
    }
    const normalizedDepotAddresses = depotAddressesResponses.map(address => {
      return {
        address: {
          line1: address.line1,
          line2: address.line2 || undefined,
          postal_code: address.postal_code,
          locality: address.locality,
          municipality: address.municipality || undefined,
          administrative_area: address.administrative_area || undefined,
          country_code: address.country_code,
          lat: address.lat,
          lng: address.lng,
        },
        contact: {
          first_name: address.first_name || loggedInUser.userData?.last_name || '',
          last_name: address.last_name || loggedInUser.userData?.last_name || '',
          phone: address.phone || loggedInUser.userData?.phone || '',
          email: address.depot_email_address_list[0] || loggedInUser.userData?.email || '',
        },
        details: {
          instructions: address.instructions || '',
        },
      };
    });
    // Update depot addressess, and set first depot contact as active
    const [firstDepot] = normalizedDepotAddresses;
    const effects = !firstDepot
      ? []
      : [
          put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_INSTRUCTIONS, firstDepot.details.instructions)),
          put(
            change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_FIRST_NAME, firstDepot.contact.first_name)
          ),
          put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_LAST_NAME, firstDepot.contact.last_name)),
          put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_EMAIL, firstDepot.contact.email)),
          put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_PHONE, firstDepot.contact.phone)),
        ];
    yield* all([put(businessActions.setDepotAddresses(normalizedDepotAddresses)), ...effects]);
  } catch (e) {
    // Lawd have mercy
    appUtils.logError(e);
    yield* put(businessActions.setDepotAddresses([]));
  }
}

function* prefillDepotInstructions(action: ReduxFormInterAction): Generator {
  if (action.meta.form !== BusinessForms.DESTINATION || action.meta.field !== BusinessFields.DEPOT_SELECT) {
    return;
  }
  const selectedDepot = yield* select(getSelectedDepot);
  if (selectedDepot) {
    yield* all([
      put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_FIRST_NAME, selectedDepot.contact.first_name)),
      put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_LAST_NAME, selectedDepot.contact.last_name)),
      put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_EMAIL, selectedDepot.contact.email)),
      put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_CONTACT_PHONE, selectedDepot.contact.phone)),
    ]);
    const instructions = selectedDepot.details.instructions;
    if (instructions) {
      yield* put(change(BusinessForms.DESTINATION, BusinessFields.DEPOT_INSTRUCTIONS, instructions));
    } else {
      yield* put(clearFields(BusinessForms.DESTINATION, false, false, BusinessFields.DEPOT_INSTRUCTIONS));
    }
  }
}

function* revalidateAddressInputs(action: ReduxFormInterAction): Generator {
  if (action.meta.form !== BusinessForms.DESTINATION) {
    return;
  }
  const formValues = yield* select(getBusinessDestinationFormValues);
  const errors = yield* select(getFormSyncErrors(BusinessForms.DESTINATION));
  // the user switched to manual input
  if (formValues?.delivery_address_is_manual_address === true) {
    // we got to make sure no errors of the autocomplete remain
    yield* put(
      updateSyncErrors(
        BusinessForms.DESTINATION,
        {
          ...errors,
          [BusinessFields.DELIVERY_AUTO_ADDRESS]: undefined,
        },
        undefined
      )
    );
  }
  // the user switched to autocomplete
  if (formValues?.delivery_address_is_manual_address === false) {
    // we got to make sure no errors of the manual attempt remain
    yield* put(
      updateSyncErrors(
        BusinessForms.DESTINATION,
        {
          ...errors,
          delivery_manual_address: undefined,
        },
        undefined
      )
    );
  }
}

function* fetchPopularItems(): Generator {
  try {
    const items = yield* call(coreClient.accounts.listPopularItems);
    yield* put(businessActions.setPopularItems(items));
  } catch (e) {
    // this can fail silent
    appUtils.logError(e);
  }
}

function* createTransportRequest(): Generator {
  yield* put(businessActions.setPriceLoading(true));
  // Check logged in status first
  // This is just to prevent the session is expired somehow (long open tabs/windows) and user managed to endup at this point.
  try {
    yield* call(coreClient.users.isUserLoggedIn);
  } catch (e) {
    appUtils.logError(e);
    yield* put(push(r.user.login()));
  }

  // we are logged in, so continue creating a transport
  const transportRequestPayload = yield* select(getTransportRequestPayload);
  try {
    if (transportRequestPayload === null) {
      yield* put(businessActions.setPriceLoading(false));
      return;
    }
    const transportRequestResponse = yield* call(
      coreClient.transportRequests.create,
      transportRequestPayload as TransportRequestCreationParams
    );
    // save uuid and navigate user to payment
    if (transportRequestResponse) {
      const uuid = getIdFromIri(transportRequestResponse['@id']) || '';
      yield* put(businessActions.setTransportRequestId(uuid));
      yield* put(businessActions.setPriceLoading(false));
      const price = transportRequestResponse.price.incl_vat.amount;
      trackEventPurchase(uuid, SourceFlow.BUSINESS, (price / 100).toFixed(2));
      yield* put(push(r.payment.thankYou({ id: uuid, type: 'business' })));
    } else {
      yield* put(businessActions.setPriceLoading(false));
    }
  } catch (e) {
    yield* put(businessActions.setPriceLoading(false));
    hotjar.fireEvent('business_transport_request_failure');
    appUtils.logError(e);
    const errorDesc = e?.response?.data?.['hydra:description'];
    appUtils.logException(`UPLOAD BUSINESS TR FAILED - Validation: ${errorDesc}`, {
      error: errorDesc,
      payload: transportRequestPayload,
    });
  }
}

export function* checkForMaxItemSetSize(action: ReduxFormInterAction): Generator {
  if (action.meta.form !== BusinessForms.ITEMS_AND_SERVICE || !action.meta.field.includes(BusinessFields.ITEM_SETS)) {
    return;
  }
  const itemsTotalSize = yield* select(getItemSetTotalSize);
  if (
    // Made it like this so you can leave the env undefined,
    // but if defined and you want to switch of m3 checking, then it should be "true"
    !Config.BUSINESS_DONT_CHECK_MAX_M3 &&
    itemsTotalSize > BUSINESS_MAX_ITEM_SET_SIZE
  ) {
    yield* put(businessActions.addBusinessFlowError('MAX_TOTAL_ITEM_SIZE'));
  } else {
    yield* put(businessActions.removeBusinessFlowError('MAX_TOTAL_ITEM_SIZE'));
  }
}

function* triggerGetNewPrice(action: ReduxFormInterAction): Generator {
  const triggerPriceFields =
    [
      BusinessFields.DEPOT_SELECT,
      BusinessFields.EXTRA_DRIVER,
      BusinessFields.ASSEMBLY,
      BusinessFields.DATE,
      BusinessFields.DELIVERY_FLOOR_LEVEL,
      BusinessFields.DELIVERY_FLOOR_LEVEL,
    ].includes(action.meta.field as BusinessFields) || action.meta.field.includes(BusinessFields.ITEM_SETS);
  const triggerPriceForms = [BusinessForms.DESTINATION, BusinessForms.ITEMS_AND_SERVICE].includes(
    action.meta.form as BusinessForms
  );
  if (!triggerPriceFields || !triggerPriceForms) {
    return;
  }
  yield* put(businessActions.getPrice());
}

export function* itemOnRemove(action): Generator {
  if (action.meta.form !== BusinessForms.ITEMS_AND_SERVICE) {
    return;
  }
  if (action.meta.field.indexOf('itemSets') > -1) {
    yield* put(businessActions.getPrice());
  }
}

function* setProgress(action): Generator {
  const path = action.payload.location.pathname;
  const pathData = matchPath(path, { path: r.businessFlow.index(), exact: false, strict: false });
  if (!pathData) {
    return;
  }
  const pathSegments = path.split('/');
  let progressStep = 1;
  switch (pathSegments[pathSegments.length - 1]) {
    case 'items':
      progressStep = 2;
      break;
    case 'preview':
      progressStep = 3;
      break;
    case 'thank_you':
      progressStep = 4;
      break;
    default:
      progressStep = 1;
  }
  yield* put(businessActions.setProgressStep(progressStep));
}

function* handleDestinationSubmit(): Generator {
  yield* put(push(r.businessFlow.items()));
}

function* handleItemsSubmit(): Generator {
  // push to preview view
  yield* put(push(r.businessFlow.preview()));
}

function* resetBusinessFlow(): Generator {
  yield* all([
    put(businessActions.setTransportRequestId(defaultState.transport_request.uuid)),
    put(businessActions.setPrice(defaultState.transport_request.price)),
    put(reset(BusinessForms.DESTINATION)),
    put(reset(BusinessForms.ITEMS_AND_SERVICE)),
  ]);
}

function* startNewTransportRequest(): Generator {
  const link = yield* select(getNewTransportRequestLink);
  yield* put(push(link));
}

function* priceHandler(): Generator {
  const priceParams = yield* select(getPriceRequestParams, false);
  if (priceParams === null) {
    yield* put(businessActions.setPriceLoading(false));
    return;
  }
  yield* put(businessActions.setPriceLoading(true));
  try {
    const quote = yield* call(priceClient.quotes.create, priceParams);
    yield* put(businessActions.setPrice(quote.price));
    yield* put(businessActions.updateBusinessPriceList(quote.price_list));
  } catch (e) {
    appUtils.logError(e);
    yield* call(priceErrorHandler, e);
  }
  yield* put(businessActions.setPriceLoading(false));
}

function* priceErrorHandler(
  error: AxiosError<{
    path?: string | null;
    title?: string | null;
    trans_key?: string | null;
    type?: string;
    errors?: string;
  }>
): Generator {
  const status = error?.response?.status;
  if (status === 503) {
    const isGeoError = [
      QuotesErrorTitles.ROUTE_CALCULATION_NOT_POSSIBLE,
      QuoteRejectedReasonsGlobal.REVERSE_GEOCODING,
    ].some(errorMessage => errorMessage === error?.response?.data?.errors);
    if (isGeoError) {
      yield* put(businessActions.setRejectedByPricing(QuoteRejectedReasonsBusiness.GEO_FAILED));
      return;
    }
  }
  const isStatusOfInterest = status && status >= 400 && status < 500;
  if (!isStatusOfInterest) {
    return;
  }
  const errorData = error.response?.data;
  if (errorData?.type !== 'business_request') {
    return;
  }
  const businessError = {
    too_far: QuoteRejectedReasonsBusiness.TOO_FAR,
    international: QuoteRejectedReasonsBusiness.INTERNATIONAL,
    exceeding_max_volume: QuoteRejectedReasonsBusiness.VOLUME,
  }[errorData.title || ''];
  if (businessError) {
    yield* put(businessActions.setRejectedByPricing(businessError));
  }
}

function* checkQuotes(action: ReduxFormInterAction): Generator {
  const isAutoAddressUpdate =
    action.type === reduxFormTypes.CHANGE && action.meta.field === BusinessFields.DELIVERY_AUTO_ADDRESS;
  const isDepotAddressUpdate =
    action.type === reduxFormTypes.CHANGE && action.meta.field === BusinessFields.DEPOT_SELECT;
  const isManualUpdate = action.type === reduxFormTypes.BLUR && action.meta.field.includes('delivery_manual_address');
  if ((!isAutoAddressUpdate && !isManualUpdate && !isDepotAddressUpdate) || action.payload === null) {
    return;
  }
  const ms = isAutoAddressUpdate || isDepotAddressUpdate ? 0 : 2000;
  // We don't work with debounce here, because we need to make sure the user did an edit to address fields
  // But we do want to wait for a bit, no need to call the API on every blur
  yield* delay(ms);
  // The goal here is to just fire off a quotes request and see if it passes
  // Error handling is done in a generic way
  yield* put(businessActions.setPriceLoading(true));
  try {
    const priceParams = yield* select(getPriceRequestParams, true, true);
    if (!priceParams) {
      return;
    }
    const quote = yield* call(priceClient.quotes.create, priceParams);
    // If we reach this, it means every is fine, but we got to make sure to reset
    yield* put(businessActions.setRejectedByPricing(null));
    yield* put(businessActions.updateBusinessPriceList(quote.price_list));
  } catch (e) {
    appUtils.logError(e);
    yield* call(priceErrorHandler, e);
  }
  yield* put(businessActions.setPriceLoading(false));
}

function* getDates(action: SetDateTimePeriodStartFromAction): Generator {
  try {
    const priceParams = yield* select(getPriceRequestParams, true, true);
    if (!priceParams) {
      return;
    }
    yield* put(businessActions.setPriceLoading(true));
    const quote = yield* call(priceClient.quotes.create, priceParams);
    let dateOptions = quote.pickup_datetime_period_options?.options || [];
    if (!action.payload.extendDateOptions) {
      /* Not extending the options means overriding existing options, so we need to clear previous date choice */
      yield* put(clearFields(BusinessForms.ITEMS_AND_SERVICE, false, false, BusinessFields.DATE));
    }
    if (action.payload.extendDateOptions) {
      const existingOptions = yield* select(
        (state: RootState) => state.business.transport_request.date_time_periods.options
      );
      dateOptions = [...existingOptions, ...dateOptions];
    }
    yield* put(businessActions.setDateTimePeriodOptions(dateOptions));
    yield* put(businessActions.updateBusinessPriceList(quote.price_list));
    yield* put(businessActions.setPriceLoading(false));
  } catch (e) {
    appUtils.logError(e);
    yield* put(businessActions.setPriceLoading(false));
  }
}

function* getNextDates(): Generator {
  const currentOptions = yield* select(
    (state: RootState) => state.business.transport_request.date_time_periods.options
  );
  /* Just to be on the save side */
  if (currentOptions.length === 0) {
    return;
  }
  /* Get the last options */
  const lastDate = addDays(new Date(currentOptions[currentOptions.length - 1].dates[0]), 1);
  const lastDateOption = formatDate(lastDate, 'api-date');
  /* Set it as start from date */
  yield* put(businessActions.setDateTimePeriodStartFrom(lastDateOption, true));
}

function* triggerFetchGeoDetails(action: ReduxFormInterAction): Generator {
  if (
    [
      BusinessFields.DELIVERY_MANUAL_LINE_1,
      BusinessFields.DELIVERY_MANUAL_POSTAL_CODE,
      BusinessFields.DELIVERY_MANUAL_LOCALITY,
      BusinessFields.DELIVERY_MANUAL_COUNTRY_CODE,
    ].includes(action.meta.field as BusinessFields)
  ) {
    yield* put(businessActions.fetchGeoDetails());
  }
}

function* fetchGeoDetails(): Generator {
  const values = yield* select(getBusinessDestinationFormValues);
  const address = values?.delivery_manual_address?.address || {};
  // Check for least amount input
  if (!address.postal_code || !address.locality || !address.country_code) {
    return;
  }
  try {
    const query = `${address.postal_code}, ${address.locality}, ${address.country_code}`;

    // Check first if we want to query with line1 included
    let autocomplete: GeoAutocomplete[] = [];
    if (address.line1) {
      autocomplete = yield* call(routePlannerClient.geo.retrieveAutocomplete, {
        sessionToken: routePlannerClientToken,
        query: `${address.line1}, ${query}`,
      });
    }
    // second attempt without line 1
    if (!autocomplete.length) {
      autocomplete = yield* call(routePlannerClient.geo.retrieveAutocomplete, {
        sessionToken: routePlannerClientToken,
        query,
      });
    }
    // There is still a chance on no match (think of newly build house block with new postal codes), we just match on locality
    if (!autocomplete.length) {
      autocomplete = yield* call(routePlannerClient.geo.retrieveAutocomplete, {
        sessionToken: routePlannerClientToken,
        query: `${address.locality}, ${address.country_code}`,
      });
    }
    const geoDetails = yield* call(routePlannerClient.geo.retrieveLocationDetails, {
      sessionToken: routePlannerClientToken,
      placeId: autocomplete[0].place_id,
    });
    // Decide how precise the result is
    let precision: CoordsPrecisionLevel = 'locality';
    if (!geoDetails.address.line1 && geoDetails.address.postal_code) {
      precision = 'postal_code';
    }
    if (geoDetails.address.line1) {
      precision = 'line1';
    }
    yield* put(
      change(BusinessForms.DESTINATION, 'delivery_manual_address', {
        address: {
          ...address,
          lat: geoDetails.address.latitude,
          lng: geoDetails.address.longitude,
          administrative_area: geoDetails.address.administrative_area,
        },
        precision,
      })
    );
  } catch (e) {
    appUtils.logError(e);
  }
}

function* businessTransportRequestSaga(): Generator {
  yield* takeLatest(UserActionTypes.SET_USER_DETAILS, determineBusinessDomain);
  yield* takeLatest(BusinessActionTypes.FETCH_ADDRESSES, fetchAddresses);
  yield* takeLatest(BusinessActionTypes.START_POPULAR_ITEMS, fetchPopularItems);
  yield* takeLatest(LOCATION_CHANGE, setProgress);
  yield* takeLatest(BusinessActionTypes.START_NEW_TR, startNewTransportRequest);
  yield* takeLatest(BusinessActionTypes.CREATE_TR, createTransportRequest);
  yield* takeLatest(BusinessActionTypes.RESET_FLOW, resetBusinessFlow);
  yield* debounce(250, reduxFormTypes.CHANGE, revalidateAddressInputs);
}

function* formInteractionSaga(): Generator {
  yield* takeLatest(reduxFormTypes.ARRAY_REMOVE, itemOnRemove);
  yield* debounce(1000, reduxFormTypes.CHANGE, checkForMaxItemSetSize);
  yield* takeEvery(reduxFormTypes.CHANGE, triggerFetchGeoDetails);
  yield* debounce(2000, BusinessActionTypes.FETCH_GEO_DETAILS, fetchGeoDetails);
  yield* takeEvery(reduxFormTypes.CHANGE, triggerGetNewPrice);
  yield* takeEvery(reduxFormTypes.CHANGE, prefillDepotInstructions);
  yield* takeLatest(BusinessActionTypes.SUBMIT_DESTINATION, handleDestinationSubmit);
  yield* takeLatest(BusinessActionTypes.SUBMIT_ITEMS, handleItemsSubmit);
  yield* takeLeading(reduxFormTypes.CHANGE, checkQuotes);
  yield* takeLeading(reduxFormTypes.BLUR, checkQuotes);
}

function* priceInteractionSaga(): Generator {
  yield* takeLatest(BusinessActionTypes.GET_PRICE, priceHandler);
  yield* takeLatest(BusinessActionTypes.SET_DATE_TIME_START_FROM, getDates);
  yield* takeLatest(BusinessActionTypes.GET_NEXT_DATE_TIME_PERIOD_OPTIONS, getNextDates);
}

export function* businessSaga(): Generator {
  yield* fork(businessTransportRequestSaga);
  yield* fork(priceInteractionSaga);
  yield* fork(formInteractionSaga);
}
