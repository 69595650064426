'use strict';

exports.__esModule = true;

var _createWebStorage = require('./createWebStorage');

var _createWebStorage2 = _interopRequireDefault(_createWebStorage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _createWebStorage2.default)('local');