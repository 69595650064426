import * as React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  isActive: boolean;
}

export const Dialog: React.FC<Props> = ({ isActive, children }) => {
  React.useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    if (isActive) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }

    return (): void => {
      body.classList.remove('overflow-hidden');
    };
  }, [isActive]);

  if (isActive) {
    const dialogRoot = document.querySelector('#root-customer-dialog') as HTMLDivElement;

    return ReactDOM.createPortal(
      <div id="dialog-container" style={{ position: 'fixed' }} role="dialog" aria-modal="true">
        {children}
      </div>,
      dialogRoot
    );
  }

  return null;
};
