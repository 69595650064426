"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconPhone = void 0;
var React = __importStar(require("react"));
var IconPhone = function (props) { return (React.createElement("svg", __assign({ width: '.8em', height: '1em', viewBox: "0 0 19 28", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M1.88881 0H16.262C16.7818 0 17.2542 0.212497 17.5964 0.55442C17.9385 0.896603 18.1508 1.36874 18.1508 1.8888V26.1112C18.1508 26.631 17.9383 27.1034 17.5964 27.4456C17.2542 27.7878 16.782 28 16.262 28H1.88881C1.36902 28 0.896617 27.7875 0.554421 27.4456C0.212238 27.1034 0 26.6313 0 26.1112V1.8888C0 1.36902 0.212498 0.896616 0.554421 0.55442C0.896604 0.212237 1.36901 0 1.88881 0V0ZM16.262 0.87499H1.88881C1.61042 0.87499 1.35704 0.989309 1.17321 1.17317C0.989362 1.35702 0.875037 1.6104 0.875037 1.88876V26.1111C0.875037 26.3895 0.989356 26.6429 1.17321 26.8267C1.35706 27.0106 1.61045 27.1249 1.88881 27.1249H16.262C16.5404 27.1249 16.7937 27.0106 16.9776 26.8267C17.1614 26.6429 17.2757 26.3895 17.2757 26.1111V1.88876C17.2757 1.61038 17.1614 1.35699 16.9776 1.17317C16.7937 0.989315 16.5403 0.87499 16.262 0.87499V0.87499Z" }),
    React.createElement("path", { d: "M0.437495 3.34998C0.195831 3.34998 0 3.54581 0 3.78747C0 4.02913 0.195831 4.22497 0.437495 4.22497H17.7133C17.955 4.22497 18.1508 4.02913 18.1508 3.78747C18.1508 3.54581 17.955 3.34998 17.7133 3.34998H0.437495Z" }),
    React.createElement("path", { d: "M0.437495 23.7744C0.195831 23.7744 0 23.9702 0 24.2119C0 24.4536 0.195831 24.6494 0.437495 24.6494H17.7133C17.955 24.6494 18.1508 24.4536 18.1508 24.2119C18.1508 23.9702 17.955 23.7744 17.7133 23.7744H0.437495Z" }),
    React.createElement("path", { d: "M5.92675 1.67505C5.68509 1.67505 5.48926 1.87088 5.48926 2.11254C5.48926 2.35421 5.68509 2.55004 5.92675 2.55004H12.2235C12.4652 2.55004 12.661 2.35421 12.661 2.11254C12.661 1.87088 12.4652 1.67505 12.2235 1.67505H5.92675Z" }),
    React.createElement("path", { d: "M7.17675 25.4497C6.93509 25.4497 6.73926 25.6455 6.73926 25.8872C6.73926 26.1289 6.93509 26.3247 7.17675 26.3247H10.9749C11.2166 26.3247 11.4124 26.1289 11.4124 25.8872C11.4124 25.6455 11.2166 25.4497 10.9749 25.4497H7.17675Z" }),
    React.createElement("path", { d: "M14.2442 2.55004C14.4859 2.55004 14.6817 2.35421 14.6817 2.11254C14.6817 1.87088 14.4859 1.67505 14.2442 1.67505H13.6182C13.3765 1.67505 13.1807 1.87088 13.1807 2.11254C13.1807 2.35421 13.3765 2.55004 13.6182 2.55004H14.2442Z" }),
    React.createElement("path", { d: "M9.39611 11.5658C8.64817 11.5658 7.99419 11.8709 7.68761 12.1761V9.32654C7.68736 9.24001 7.65218 9.1571 7.58976 9.09591C7.52733 9.03473 7.44274 9.00025 7.35446 9H6.33316C6.2448 9 6.16006 9.0344 6.09758 9.09564C6.0351 9.15688 6 9.23994 6 9.32654V14.3735C6.00025 14.4106 6.01546 14.4461 6.04231 14.4722C6.06916 14.4983 6.10548 14.513 6.14332 14.513H7.44178C7.4771 14.5132 7.51119 14.5004 7.53736 14.4771C7.56353 14.4539 7.57989 14.4219 7.58321 14.3874C7.62117 14.006 7.84897 12.884 9.2376 12.884C10.6044 12.884 10.8512 13.9744 10.8901 14.3725C10.8941 14.4117 10.9129 14.448 10.9427 14.4743C10.9725 14.5006 11.0113 14.5151 11.0515 14.5149H12.2977C12.3198 14.5149 12.3418 14.5105 12.3621 14.5019C12.3824 14.4934 12.4008 14.4808 12.416 14.465C12.4312 14.4493 12.4429 14.4306 12.4505 14.4102C12.4581 14.3898 12.4613 14.3682 12.46 14.3465C12.3917 13.2459 11.6494 11.5658 9.39611 11.5658Z" }),
    React.createElement("path", { d: "M12.2983 15.9358H11.0311C10.9944 15.9354 10.9587 15.9474 10.9299 15.9698C10.9012 15.9922 10.8812 16.0236 10.8733 16.0588C10.7995 16.4335 10.595 16.7712 10.2951 17.014C9.99509 17.2567 9.61833 17.3893 9.22955 17.389C8.84077 17.3887 8.46425 17.2554 8.1647 17.0121C7.86516 16.7689 7.66131 16.4308 7.58819 16.056C7.58048 16.0209 7.56066 15.9895 7.53209 15.9671C7.50351 15.9447 7.46792 15.9326 7.43135 15.933H6.16226C6.13977 15.9329 6.11752 15.9374 6.09691 15.9463C6.0763 15.9551 6.0578 15.9681 6.04259 15.9843C6.02738 16.0006 6.01579 16.0198 6.00857 16.0407C6.00134 16.0616 5.99864 16.0837 6.00064 16.1057C6.09318 16.8827 6.47324 17.5993 7.06863 18.1194C7.66402 18.6395 8.43326 18.9268 9.23026 18.9268C10.0273 18.9268 10.7965 18.6395 11.3919 18.1194C11.9873 17.5993 12.3673 16.8827 12.4599 16.1057C12.4615 16.084 12.4584 16.0621 12.451 16.0415C12.4436 16.021 12.432 16.0021 12.4168 15.9862C12.4016 15.9702 12.3833 15.9575 12.3629 15.9489C12.3425 15.9402 12.3205 15.9358 12.2983 15.9358Z" }))); };
exports.IconPhone = IconPhone;
