import React from 'react';
import { Dialog } from '../../../../brenger-shared-ui';
interface Props {
  isActive: boolean;
  closeHandler(): void;
}

export const Modal: React.FC<Props> = ({ isActive, closeHandler, children }) => {
  return (
    <Dialog isActive={isActive}>
      <div className="modal--backdrop" onClick={closeHandler}>
        <div style={{ margin: 'auto', maxWidth: 470 }}>
          <div className={'contact-modal--content'} onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
