"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconGoogleMaps = void 0;
var React = __importStar(require("react"));
var IconGoogleMaps = function (props) {
    return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 52 52", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { filter: "url(#filter0_d_140_3896)" },
            React.createElement("rect", { x: "4", width: "44", height: "44", rx: "10", fill: "white" })),
        React.createElement("g", { clipPath: "url(#clip0_140_3896)" },
            React.createElement("path", { d: "M26.119 22.4286L37.7751 8.95209C37.5825 8.89043 37.3781 8.85718 37.1667 8.85718H15.0714C13.9321 8.85718 13 9.82146 13 11V33.8571C13 34.0759 13.0321 34.2873 13.0917 34.4865L26.119 22.4286Z", fill: "#0F9D58" }),
            React.createElement("path", { d: "M26.1191 22.4285L14.4631 35.905C14.6557 35.9666 14.86 35.9999 15.0715 35.9999H37.1667C37.3782 35.9999 37.5825 35.9666 37.7751 35.905L26.1191 22.4285Z", fill: "#4285F4" }),
            React.createElement("path", { d: "M26.1189 22.4287L39.1462 34.4868C39.2058 34.2875 39.238 34.0762 39.238 33.8574V11C39.238 10.7812 39.2058 10.5698 39.1462 10.3706L26.1189 22.4287Z", fill: "#D2D2D2" }),
            React.createElement("path", { d: "M39.2382 33.8572L26.8096 21L24.3929 23.1429L36.8215 36H37.1668C38.306 36 39.2382 35.0357 39.2382 33.8572Z", fill: "#F1F1F1" }),
            React.createElement("path", { d: "M37.1667 8.85718L13 33.8571C13 35.0357 13.9321 36 15.0714 36H15.4167L39.2381 11.3572V11C39.2381 9.82146 38.306 8.85718 37.1667 8.85718Z", fill: "#FFDE48" }),
            React.createElement("path", { d: "M37.1667 8.85718H15.0714C13.9321 8.85718 13 9.82145 13 11V11.1786C13 10 13.9321 9.03574 15.0714 9.03574H37.1667C38.3059 9.03574 39.2381 10 39.2381 11.1786V11C39.2381 9.82145 38.306 8.85718 37.1667 8.85718Z", fill: "white", fillOpacity: "0.2" }),
            React.createElement("path", { d: "M37.1667 35.8213H15.0714C13.9321 35.8213 13 34.857 13 33.6785V33.857C13 35.0356 13.9321 35.9999 15.0714 35.9999H37.1667C38.3059 35.9999 39.2381 35.0356 39.2381 33.857V33.6785C39.2381 34.857 38.306 35.8213 37.1667 35.8213Z", fill: "#263238", fillOpacity: "0.1" }),
            React.createElement("path", { d: "M18.869 14.2143V15.7358H20.9128C20.7506 16.6394 19.9842 17.2965 18.869 17.2965C17.6296 17.2965 16.6215 16.2108 16.6215 14.9322C16.6215 13.6536 17.6296 12.5679 18.869 12.5679C19.4283 12.5679 19.9255 12.7679 20.3225 13.1536L21.41 12.0286C20.7506 11.3893 19.8944 11 18.869 11C16.77 11 15.0714 12.7572 15.0714 14.9286C15.0714 17.1001 16.77 18.8573 18.869 18.8573C21.0613 18.8573 22.5148 17.2608 22.5148 15.0179C22.5148 14.7394 22.4906 14.4715 22.4457 14.2143H18.869V14.2143Z", fill: "#EEEEEE" }),
            React.createElement("g", { opacity: "0.25", filter: "url(#filter1_f_140_3896)" },
                React.createElement("path", { d: "M35.0952 6C31.2804 6 28.1905 9.19643 28.1905 13.1429C28.1905 18.525 34.0008 21.3286 34.6879 29.0107C34.7086 29.225 34.8846 29.3929 35.0952 29.3929C35.3058 29.3929 35.4854 29.225 35.5026 29.0107C36.1896 21.3286 42 18.525 42 13.1429C42 9.19643 38.9101 6 35.0952 6Z", fill: "#DB4437" })),
            React.createElement("path", { d: "M35.0952 6C31.2804 6 28.1905 9.19643 28.1905 13.1429C28.1905 18.525 34.0008 21.3286 34.6879 29.0107C34.7086 29.225 34.8846 29.3929 35.0952 29.3929C35.3058 29.3929 35.4854 29.225 35.5026 29.0107C36.1896 21.3286 42 18.525 42 13.1429C42 9.19643 38.9101 6 35.0952 6Z", fill: "#DB4437" }),
            React.createElement("path", { d: "M35.0952 15.6429C36.4299 15.6429 37.5118 14.5236 37.5118 13.1429C37.5118 11.7621 36.4299 10.6428 35.0952 10.6428C33.7606 10.6428 32.6786 11.7621 32.6786 13.1429C32.6786 14.5236 33.7606 15.6429 35.0952 15.6429Z", fill: "#7B231E" }),
            React.createElement("path", { d: "M35.0952 6.17857C38.8756 6.17857 41.9448 9.32143 41.9965 13.2179C41.9965 13.1929 42 13.1679 42 13.1429C42 9.19643 38.9101 6 35.0952 6C31.2803 6 28.1905 9.19643 28.1905 13.1429C28.1905 13.1679 28.1939 13.1929 28.1939 13.2179C28.2457 9.32143 31.3149 6.17857 35.0952 6.17857Z", fill: "white", fillOpacity: "0.2" }),
            React.createElement("path", { d: "M35.5026 28.8322C35.4853 29.0464 35.3058 29.2143 35.0952 29.2143C34.8846 29.2143 34.7051 29.0464 34.6879 28.8322C34.0077 21.1857 28.2492 18.3715 28.1939 13.0393C28.1939 13.075 28.1905 13.1072 28.1905 13.1429C28.1905 18.525 34.0008 21.3286 34.6879 29.0107C34.7086 29.225 34.8846 29.3929 35.0952 29.3929C35.3058 29.3929 35.4854 29.225 35.5026 29.0107C36.1896 21.3286 42 18.525 42 13.1429C42 13.1072 41.9965 13.075 41.9965 13.0393C41.9413 18.3714 36.1862 21.1893 35.5026 28.8322Z", fill: "#3E2723", fillOpacity: "0.2" }),
            React.createElement("path", { d: "M35.0952 6C32.8374 6 30.8315 7.12143 29.5714 8.85714H15.0714C13.9321 8.85714 13 9.82143 13 11V33.8571C13 35.0357 13.9321 36 15.0714 36H37.1667C38.3059 36 39.2381 35.0357 39.2381 33.8571V19.8679C40.6881 17.675 42 15.7 42 13.1429C42 9.19643 38.9101 6 35.0952 6Z", fill: "url(#paint0_radial_140_3896)" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_140_3896", x: "0", y: "0", width: "52", height: "52", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.132866 0 0 0 0 0.229798 0 0 0 0 0.34468 0 0 0 0.1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_140_3896" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_140_3896", result: "shape" })),
            React.createElement("filter", { id: "filter1_f_140_3896", x: "-1171.81", y: "-1194", width: "2413.81", height: "2423.39", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
                React.createElement("feGaussianBlur", { stdDeviation: "600", result: "effect1_foregroundBlur_140_3896" })),
            React.createElement("radialGradient", { id: "paint0_radial_140_3896", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(13.7198 9.44857) scale(35.3683 36.5879)" },
                React.createElement("stop", { stopColor: "white", stopOpacity: "0.1" }),
                React.createElement("stop", { offset: "1", stopColor: "white", stopOpacity: "0" })),
            React.createElement("clipPath", { id: "clip0_140_3896" },
                React.createElement("rect", { width: "29", height: "30", fill: "white", transform: "translate(13 6)" })))));
};
exports.IconGoogleMaps = IconGoogleMaps;
