"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStopHand = void 0;
var React = __importStar(require("react"));
var IconStopHand = function (props) { return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 107 107", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M53.8232 46.7603C53.8671 48.5431 53.5479 50.5513 52.9037 50.6013C52.2593 50.6513 51.74 49.1062 51.6274 47.5923C51.5149 46.0784 42.1376 53.0284 41.5746 53.3037C41.1493 53.5102 36.6702 54.1482 33.3672 55.5871C32.2662 56.0687 30.1893 58.5898 29.9141 58.6085C28.8069 58.6898 28.1124 57.9829 27.7308 55.1429C27.3493 52.3029 19.1231 60.1287 19.8926 63.1501C21.2063 68.3173 19.905 83.2184 28.6504 90.7627C38.772 99.4956 57.2513 97.0246 63.9386 89.2801C70.6259 81.5355 70.8886 76.6373 76.6376 70.2691C81.5045 64.8767 87.7664 65.7025 90.1437 62.9562C92.1392 60.6416 89.1864 57.1197 88.0605 56.15C86.0587 54.4235 78.7271 52.5655 72.903 59.3154C70.6385 61.9366 67.8422 63.6693 65.1522 61.3485C62.4622 59.0277 62.65 52.7595 63.313 48.3179", fill: "url(#paint0_linear_4_8858)" }),
    React.createElement("path", { d: "M90.1499 62.9562C91.2321 61.6988 90.8568 60.0849 90.1062 58.7149C87.6289 64.2699 80.7164 58.6398 67.7547 73.6659C54.7928 88.692 42.269 85.5141 38.0027 83.137C33.7363 80.7598 27.1554 75.4676 27.4494 65.3084C27.537 62.1868 27.2305 58.4522 26.6049 54.6299C24.2778 55.3993 19.2857 60.773 19.8925 63.1564C21.2062 68.3236 19.9051 83.2246 28.6505 90.7689C38.7721 99.5018 57.2514 97.0308 63.9387 89.2862C70.626 81.5417 70.8887 76.6437 76.6377 70.2753C81.5109 64.8829 87.7728 65.7087 90.1499 62.9562Z", fill: "url(#paint1_linear_4_8858)" }),
    React.createElement("path", { d: "M27.7315 55.1492C27.3499 52.309 27.0371 44.9023 26.2614 40.7486C25.4857 36.5948 23.2775 34.7306 19.8743 35.644C16.4713 36.5572 17.5035 44.2017 17.6411 46.0596C17.8538 48.9123 19.1237 60.1287 19.8931 63.1564", fill: "url(#paint2_linear_4_8858)" }),
    React.createElement("path", { d: "M41.5817 53.3095C41.0187 53.5847 39.4798 52.784 39.3234 47.842C39.2045 44.0636 39.2484 33.529 38.5603 28.1429C37.8721 22.7568 36.4647 19.5789 33.2305 19.2911C29.99 19.0034 28.5449 22.9758 28.62 27.4109C28.7139 32.8909 29.1768 38.79 29.6084 44.4513C29.9462 48.8742 31.0221 58.5329 29.9212 58.6143", fill: "url(#paint3_linear_4_8858)" }),
    React.createElement("path", { d: "M29.6073 44.4518C29.9263 48.6242 30.9022 57.4572 30.0826 58.5082C31.7529 58.0077 33.2918 53.5975 32.9165 51.2579C30.7457 37.7268 32.3786 20.3361 31.1212 19.8169C29.3507 21.018 28.5501 24.0895 28.6063 27.4112C28.7127 32.8849 29.1694 38.7903 29.6073 44.4518Z", fill: "url(#paint4_linear_4_8858)" }),
    React.createElement("path", { d: "M63.3264 48.318C63.9957 43.8766 64.5399 28.2186 64.9904 24.0273C65.4407 19.8361 63.0073 17.7404 60.2923 17.4714C57.5836 17.2024 55.0314 19.148 54.4746 27.468C54.1243 32.7165 53.5863 37.3644 53.8177 46.7667L59.7106 51.1769L63.3264 48.318Z", fill: "url(#paint5_linear_4_8858)" }),
    React.createElement("path", { d: "M51.6339 47.5982C51.5212 46.0843 51.6839 31.371 51.6339 28.1431C51.54 22.0938 54.0047 11.4404 46.5793 10.7273C42.6757 10.3519 41.8438 13.974 41.6685 15.5629C41.4935 17.1519 41.5309 35.1744 41.6498 36.8008C41.7687 38.4274 42.144 53.028 41.5748 53.3033", fill: "url(#paint6_linear_4_8858)" }),
    React.createElement("path", { d: "M53.823 46.7604C53.8668 48.4745 53.5728 50.3949 52.9785 50.5827C57.8891 50.7515 57.6702 38.6155 57.7454 31.5091C57.8017 26.0104 60.5667 21.3312 60.5165 17.4964C60.4478 17.484 60.3728 17.4777 60.3039 17.4714C57.5952 17.2024 55.0428 19.148 54.4861 27.468C54.1296 32.7165 53.5916 37.3644 53.823 46.7604Z", fill: "url(#paint7_linear_4_8858)" }),
    React.createElement("path", { d: "M41.6507 36.8066C41.7695 38.433 42.145 53.0336 41.5756 53.3089C46.5427 52.2017 45.4854 35.4303 45.1351 27.1165C44.8348 19.9099 47.1995 15.8688 45.2414 10.7642C42.4702 11.2333 41.8195 14.1735 41.6632 15.5622C41.4943 17.1575 41.5318 35.1737 41.6507 36.8066Z", fill: "url(#paint8_linear_4_8858)" }),
    React.createElement("path", { d: "M41.2559 53.3471C40.8556 53.2782 40.3113 52.834 39.8984 51.6455C40.0736 52.6714 40.4865 53.3532 41.2559 53.3471Z", fill: "url(#paint9_linear_4_8858)" }),
    React.createElement("path", { d: "M87.7918 57.5451C89.9937 58.8463 90.1439 59.3593 90.1002 58.7149C89.4684 57.564 88.58 56.588 88.0671 56.1501C86.0652 54.4235 78.7336 52.5656 72.9096 59.3155C70.645 61.9366 67.8487 63.6694 65.1588 61.3485C64.1016 60.4353 63.4885 58.9027 63.1758 57.1198C63.3884 66.4094 71.3332 64.6828 74.8051 61.799C78.277 58.9027 81.3234 53.7229 87.7918 57.5451Z", fill: "url(#paint10_radial_4_8858)" }),
    React.createElement("path", { d: "M59.2788 17.4829C56.9706 17.7956 54.9687 20.1979 54.4808 27.4607C54.1305 32.7091 53.5924 37.3571 53.824 46.7594C53.8677 48.5422 53.5487 50.5503 52.9043 50.6003C58.4219 45.8211 55.2753 33.7414 56.3074 27.4294C57.1206 22.4374 58.3843 20.1666 59.2788 17.4829Z", fill: "url(#paint11_linear_4_8858)" }),
    React.createElement("path", { d: "M41.6507 36.8066C41.7695 38.4331 42.145 53.0338 41.5756 53.309C44.228 53.553 44.6784 50.7692 44.3344 46.0463C43.6213 36.3688 42.4139 23.1443 44.4658 14.8868C44.6597 14.1048 44.4971 13.2666 43.959 12.666C42.564 11.1084 41.7945 14.355 41.6632 15.5687C41.4943 17.1576 41.5318 35.1739 41.6507 36.8066Z", fill: "url(#paint12_linear_4_8858)" }),
    React.createElement("path", { d: "M28.6141 27.4112C28.7079 32.8912 29.1708 38.7902 29.6025 44.4516C29.9402 48.8744 31.0162 58.5332 29.9152 58.6145C35.6829 58.6895 28.9331 35.1182 31.1226 19.8105C29.3586 21.0179 28.5578 24.0832 28.6141 27.4112Z", fill: "url(#paint13_linear_4_8858)" }),
    React.createElement("path", { d: "M28.7441 58.5826C29.1694 58.7327 29.4636 58.7264 29.6512 58.6138C28.7002 58.5637 28.081 57.7505 27.7307 55.1482C27.3491 52.3081 27.0363 44.9014 26.2606 40.7477C25.9228 38.9209 25.3035 37.5385 24.4277 36.6377C25.0533 38.652 24.1586 38.4143 25.1783 43.3249C25.7226 45.9586 25.9791 57.6192 28.7441 58.5826Z", fill: "url(#paint14_linear_4_8858)" }),
    React.createElement("path", { d: "M37.7459 40.1917C37.6396 44.7522 38.1088 52.8595 41.0865 53.2974C40.4046 53.0221 39.4475 51.6834 39.3224 47.8362C39.2035 44.0578 39.2473 33.5232 38.5591 28.1371C38.0025 23.7707 36.9765 20.8618 34.8809 19.7607C38.5154 25.8163 37.8523 35.8753 37.7459 40.1917Z", fill: "url(#paint15_linear_4_8858)" }),
    React.createElement("path", { d: "M50.0384 30.7452C50.2512 37.5763 48.9312 49.6061 52.7033 50.5632C52.1528 50.3192 51.7275 48.9492 51.6273 47.5979C51.5147 46.084 51.6773 31.3707 51.6273 28.1428C51.5649 23.9516 52.7221 17.5519 50.9768 13.7798C51.9089 18.5529 49.8633 25.2151 50.0384 30.7452Z", fill: "url(#paint16_linear_4_8858)" }),
    React.createElement("path", { d: "M63.176 57.1122C62.6944 54.3659 62.9196 51.0003 63.3262 48.3168C63.9955 43.8752 64.5399 28.2172 64.9902 24.026C65.1278 22.7498 64.9965 21.6676 64.6712 20.7729C64.471 22.7123 63.4576 24.8517 63.5139 28.6364C63.6078 34.7169 62.2691 44.3507 61.8123 47.6599C61.3557 50.9753 59.7104 57.3687 63.176 57.1122Z", fill: "url(#paint17_linear_4_8858)" }),
    React.createElement("path", { d: "M46.8856 96.2487C48.1304 92.5015 47.9303 89.3862 44.1643 88.892C33.8926 87.5533 27.612 79.5961 24.7593 63.8068C22.207 49.7253 22.0569 41.0925 19.4796 35.7939C16.5643 37.1827 17.509 44.2766 17.6404 46.0595C17.853 48.9121 19.123 60.1286 19.8924 63.1563C21.2061 68.3234 19.9049 83.2244 28.6504 90.7688C33.5423 94.985 40.3735 96.5865 46.8856 96.2487Z", fill: "url(#paint18_linear_4_8858)" }),
    React.createElement("path", { d: "M76.6445 70.2691C81.5114 64.8767 87.7733 65.7025 90.1504 62.9562C90.8511 62.1493 90.9387 61.1922 90.7135 60.2412C89.5999 63.0125 86.2156 63.4067 80.8358 64.4012C75.0055 65.4773 69.6881 70.3755 66.9357 77.4819C64.1832 84.5883 55.2751 89.9807 49.2947 88.9609C43.3081 87.9414 29.0764 93.8591 24.2783 69.1494C24.2783 69.1431 24.2783 69.1368 24.2721 69.1305C24.0906 67.4915 23.8342 65.9652 23.4088 64.6077C20.4687 55.2305 19.8993 48.6058 19.7366 43.2884C19.6992 42.2437 20.1934 38.7718 17.8474 38.0273C17.0279 40.7049 17.5472 44.771 17.6411 46.0597C17.8537 48.9123 19.1237 60.1286 19.8931 63.1564C19.9932 63.5505 20.0808 64.0009 20.1558 64.5014C20.1745 64.6328 20.1934 64.7641 20.2121 64.9017C20.2184 64.9393 20.2247 64.9705 20.2247 65.0081C20.2371 65.0957 20.2497 65.1833 20.2559 65.2707C20.2559 65.2896 20.2622 65.3083 20.2622 65.3333C20.6876 68.8115 20.844 74.1039 22.1639 79.2772V79.2835C22.2827 79.7402 22.4079 80.1906 22.5392 80.641C22.5392 80.6472 22.5455 80.6535 22.5455 80.6598C22.6769 81.1039 22.8208 81.548 22.9771 81.9922C22.9771 81.9985 22.9771 81.9985 22.9834 82.0047C24.1406 85.3014 25.8985 88.4043 28.6448 90.7751C38.7665 99.5081 57.2456 97.037 63.933 89.2925C70.6202 81.548 70.8892 76.6374 76.6445 70.2691Z", fill: "url(#paint19_radial_4_8858)" }),
    React.createElement("path", { d: "M70.1004 70.8813C71.6706 72.7643 66.4033 80.709 62.4122 84.0308C58.4211 87.3525 53.9108 88.5224 52.3405 86.6393C50.7705 84.7564 54.3299 80.0334 57.4265 75.8671C62.7813 68.6731 68.5365 68.9983 70.1004 70.8813Z", fill: "url(#paint20_radial_4_8858)" }),
    React.createElement("path", { opacity: "0.75", d: "M25.6034 69.0929C23.8644 71.1822 29.1504 83.2244 34.3614 86.2584C39.5724 89.2924 46.1033 88.3603 47.3794 85.9518C48.6556 83.5435 43.2319 79.7962 38.8029 75.6488C31.1522 68.4861 27.3426 67.0035 25.6034 69.0929Z", fill: "url(#paint21_radial_4_8858)" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_4_8858", x1: "41.5022", y1: "105.348", x2: "50.0228", y2: "78.6112", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint1_linear_4_8858", x1: "54.8889", y1: "93.19", x2: "55.4015", y2: "57.2292", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint2_linear_4_8858", x1: "20.1204", y1: "38.9516", x2: "23.8787", y2: "55.4373", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint3_linear_4_8858", x1: "33.7035", y1: "15.6215", x2: "35.4554", y2: "50.1579", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint4_linear_4_8858", x1: "37.8868", y1: "38.8436", x2: "27.0482", y2: "39.3167", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("linearGradient", { id: "paint5_linear_4_8858", x1: "60.945", y1: "23.5481", x2: "57.2088", y2: "49.0979", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint6_linear_4_8858", x1: "47.7459", y1: "16.9386", x2: "45.386", y2: "48.4719", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.00132565", stopColor: "#FFCB4B" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748" })),
        React.createElement("linearGradient", { id: "paint7_linear_4_8858", x1: "62.6826", y1: "33.879", x2: "46.6781", y2: "34.2378", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("linearGradient", { id: "paint8_linear_4_8858", x1: "49.4157", y1: "32.5233", x2: "29.2425", y2: "30.8925", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("linearGradient", { id: "paint9_linear_4_8858", x1: "47.7533", y1: "53.08", x2: "27.5801", y2: "51.4492", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("radialGradient", { id: "paint10_radial_4_8858", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(76.6527 52.4) rotate(40.7903) scale(19.6143)" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("linearGradient", { id: "paint11_linear_4_8858", x1: "65.7062", y1: "33.7036", x2: "51.8762", y2: "34.1948", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.3118", stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FF8900" })),
        React.createElement("linearGradient", { id: "paint12_linear_4_8858", x1: "56.4911", y1: "32.4863", x2: "38.4996", y2: "32.8743", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.3118", stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FF8900" })),
        React.createElement("linearGradient", { id: "paint13_linear_4_8858", x1: "44.5579", y1: "39.401", x2: "23.0064", y2: "39.115", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.3118", stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FF8900" })),
        React.createElement("linearGradient", { id: "paint14_linear_4_8858", x1: "24.3436", y1: "35.2603", x2: "28.6802", y2: "59.6018", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFBC47" })),
        React.createElement("linearGradient", { id: "paint15_linear_4_8858", x1: "39.753", y1: "23.0244", x2: "36.2131", y2: "50.0518", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFBC47" })),
        React.createElement("linearGradient", { id: "paint16_linear_4_8858", x1: "47.572", y1: "31.9198", x2: "52.8608", y2: "32.2326", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFA754" })),
        React.createElement("linearGradient", { id: "paint17_linear_4_8858", x1: "54.9893", y1: "55.1125", x2: "68.2076", y2: "31.2051", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFBC47" })),
        React.createElement("linearGradient", { id: "paint18_linear_4_8858", x1: "28.5275", y1: "66.172", x2: "17.1011", y2: "66.5304", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: "0.3118", stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FF8900" })),
        React.createElement("radialGradient", { id: "paint19_radial_4_8858", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(49.4492 50.4565) rotate(88.9305) scale(56.8519 51.6061)" },
            React.createElement("stop", { offset: "0.6134", stopColor: "#FFBC47", stopOpacity: "0" }),
            React.createElement("stop", { offset: "1", stopColor: "#FF8900" })),
        React.createElement("radialGradient", { id: "paint20_radial_4_8858", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(61.1425 78.4954) rotate(-39.7659) scale(11.7936 5.47437)" },
            React.createElement("stop", { stopColor: "#FBE07A" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748", stopOpacity: "0" })),
        React.createElement("radialGradient", { id: "paint21_radial_4_8858", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(35.9111 79.4732) rotate(-138.965) scale(15.1771 6.92465)" },
            React.createElement("stop", { stopColor: "#FBE07A" }),
            React.createElement("stop", { offset: "0.2654", stopColor: "#FCDF73", stopOpacity: "0.7346" }),
            React.createElement("stop", { offset: "0.6548", stopColor: "#FDDB5F", stopOpacity: "0.3452" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFD748", stopOpacity: "0" }))))); };
exports.IconStopHand = IconStopHand;
