import * as React from 'react';
import './flashmessage.scss';

type MessageType = 'success' | 'info' | 'info-light' | 'error';
interface Props {
  type: MessageType;
  message?: string;
}

export const FlashMessage: React.FC<Props> = ({ type, message, children }) => {
  if (message && (message.indexOf('</') > 0 || message.indexOf('/>') > 0)) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return <div className={'message message--' + type} dangerouslySetInnerHTML={{ __html: message }} />;
  } else if (children) {
    return <div className={'message message--' + type}>{children}</div>;
  } else {
    return <div className={'message message--' + type}>{message}</div>;
  }
};
export default FlashMessage;
