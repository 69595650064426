import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { Dispatch } from 'redux';
import { Button, Container, Heading, IconArrow, SectionWrapper, SimpleSwitch } from '../../../brenger-shared-ui';
import { RootState, CacheKey } from '../../../typings';
import { coreClient } from '../../../utils/request';
import { apiSettingsActions } from '../ducks';
import { Environment } from '../typings';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ApiSettingsLayout: React.FC<Props> = props => {
  const apiKeys = useQuery([CacheKey.API_KEY_LIST], coreClient.apiKeys.list);
  const hasTestKeys = (apiKeys.data?.['hydra:member'] || []).some(key => key.test);
  const options: Environment[] = ['production', 'development'];
  return (
    <Container fluid={true}>
      <div className={'main-centered-content'}>
        <Button buttonStyle={'tertiary'} iconPos={'left'} onClick={() => props.goToDashboard()}>
          <IconArrow left={true} arrowStyle={'primary'} />
          <Translate id={'api_settings.back_to_dashboard'} />
        </Button>
      </div>
      <SectionWrapper sectionStyle="primary-light">
        <div className={'api-settings--header'}>
          <Heading size={3}>
            <Translate id={'api_settings.page_title'} />
          </Heading>
          {hasTestKeys && (
            <div style={{ width: '145px' }}>
              <SimpleSwitch options={options} activeValue={props.environment} setValue={props.setEnvironment} />
            </div>
          )}
        </div>
      </SectionWrapper>

      <SectionWrapper sectionStyle="white">{props.children}</SectionWrapper>
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  environment: state.apiSettings.environment,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEnvironment: environment => dispatch(apiSettingsActions.setEnvironment(environment)),
  goToDashboard: () => dispatch(push('/dashboard')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSettingsLayout);
