/*
/* BRENGER LOCALIZATION UTILS
/* ================================================== */
import { TextScanLangs } from '@brenger/utils';
import React from 'react';
import { Translate, getTranslate } from 'react-localize-redux-dep-updated';
import { store } from '..';
import { Config } from '../config';

export const translate = (translateKey, data = {}): string => {
  return getTranslate(store.getState().localize)(translateKey, data) as string;
};

// NL-BE is not supported in the flow, we use the NL version
export type SupportedLanguages = 'nl' | 'en-nl' | 'de-de';
export type SupportedLanguagesShort = 'nl' | 'en' | 'de';
export type SupportedCountries = 'nl' | 'de' | 'gb';
export type SupportedLanguagesFull = 'nl-NL' | 'en-NL' | 'de-DE';
export type SupportedTimeZones = 'Europe/Amsterdam' | 'Europe/Madrid' | 'Europe/Berlin';

// This is not exported on purpose, please use active language settings, further down
const getActiveLanguage = (): SupportedLanguages => {
  const langs = Config.SUPPORTED_LANGUAGES;
  const langPrefixInPath = window?.location?.pathname?.split('/')[1] || '';
  return (langs.find(lang => langPrefixInPath === lang) || 'en-nl') as SupportedLanguages;
};

export const ACTIVE_LANG = getActiveLanguage();

export interface LanguageSettings {
  labels: {
    short: SupportedLanguagesShort;
    full: SupportedLanguagesFull;
    contentSite: SupportedLanguages;
    /**
     * BE CAREFULL
     * This is used in the business fields (of general flow) to render the country select.
     */
    countryCode: SupportedCountries;
    flag: string;
    currency: 'EUR' | 'GBP';
    number_suffixes: Map<Intl.LDMLPluralRule, string>;
    textScanLangs: TextScanLangs[]; // used in combination with textscanning utils, can be multiple langs
  };
  altLanguages?: { lang: SupportedLanguages; label: string }[];
  timeZone: SupportedTimeZones;
  contact: {
    [key in 'phone' | 'email']: {
      [k in 'customer' | 'business']: string;
    };
  };
  home_page: string;
  strings: {
    loading: string;
  };
}

export const allLanguageSettings: LanguageSettings[] = [
  // english should be the first option, which will be treated as default
  {
    labels: {
      short: 'en',
      full: 'en-NL',
      contentSite: 'en-nl',
      countryCode: 'gb',
      flag: '🇬🇧',
      currency: 'EUR',
      number_suffixes: new Map([
        ['one', 'st'],
        ['two', 'nd'],
        ['few', 'rd'],
        ['other', 'th'],
      ]),
      textScanLangs: ['nl', 'en'],
    },
    altLanguages: [{ lang: 'nl', label: 'Nederlands' }],
    timeZone: 'Europe/Amsterdam',
    contact: {
      phone: {
        customer: '+31 85 8881124',
        business: '+31 85 8880490',
      },
      email: {
        customer: 'info@brenger.nl',
        business: 'info@brenger.nl',
      },
    },
    home_page: 'https://www.brenger.nl/en-nl',
    strings: {
      loading: 'Busy loading',
    },
  },
  {
    labels: {
      short: 'nl',
      full: 'nl-NL',
      contentSite: 'nl',
      countryCode: 'nl',
      flag: '🇳🇱',
      currency: 'EUR',
      number_suffixes: new Map([['other', 'e']]),
      textScanLangs: ['nl', 'en'],
    },
    altLanguages: [{ lang: 'en-nl', label: 'English' }],

    timeZone: 'Europe/Amsterdam',
    contact: {
      phone: {
        customer: '+31 85 8881124',
        business: '+31 85 8880490',
      },
      email: {
        customer: 'info@brenger.nl',
        business: 'info@brenger.nl',
      },
    },
    home_page: 'https://www.brenger.nl/nl',
    strings: {
      loading: 'Bezig met laden',
    },
  },
  {
    labels: {
      short: 'de',
      full: 'de-DE',
      contentSite: 'de-de',
      countryCode: 'de',
      flag: '🇩🇪',
      currency: 'EUR',
      number_suffixes: new Map([['other', '.']]),
      textScanLangs: ['de'],
    },
    timeZone: 'Europe/Berlin',
    contact: {
      phone: {
        customer: '+49 282 2911 3057',
        business: '+49 282 2911 3057',
      },
      email: {
        customer: 'info@brengertransport.com',
        business: 'info@brengertransport.com',
      },
    },
    home_page: 'https://www.brengertransport.com/de-de',
    strings: {
      loading: 'Wird geladen',
    },
  },
];

export const getActiveLanguageSettings = (): LanguageSettings => {
  const activeLang = getActiveLanguage();
  return (
    allLanguageSettings.find(langSetting => langSetting.labels.contentSite === activeLang) || allLanguageSettings[0]
  );
};

export const ACTIVE_LANG_SETTINGS = getActiveLanguageSettings();

/**
 * Simple wrapper function for help data in translations
 */
interface HelpTrans {
  id: string;
  type?: 'customer' | 'business';
}
export const helpTrans = {
  data: (type: HelpTrans['type'] = 'customer') => {
    return {
      phone: (
        <a style={{ display: 'inline-block' }} href={'tel:' + ACTIVE_LANG_SETTINGS.contact.phone[type]}>
          {ACTIVE_LANG_SETTINGS.contact.phone[type]}
        </a>
      ),
      email: (
        <a style={{ display: 'inline-block' }} href={'mailto:' + ACTIVE_LANG_SETTINGS.contact.email[type]}>
          {ACTIVE_LANG_SETTINGS.contact.email[type]}
        </a>
      ),
      link: (
        <a
          target="_blank"
          href={`${ACTIVE_LANG_SETTINGS.home_page}${type === 'business' ? '/business' : ''}/contact`}
          rel="noreferrer"
          style={{ display: 'inline-block' }}
        >
          <Translate id="request_flow.actions.contact" />
        </a>
      ),
    };
  },
  asString: (args: HelpTrans): string => {
    return translate(args.id, helpTrans.data(args.type));
  },
  // eslint-disable-next-line react/display-name
  asComponent: (args: HelpTrans): JSX.Element => {
    return <Translate id={args.id} data={helpTrans.data(args.type)} />;
  },
};
