import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState, StopType } from '../../../typings';
import {
  FlashMessage,
  Heading,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Config } from '../../../config';
import { hotjar } from '../../../configs/hotjar';
import { actions as flowActions } from '../ducks';
import FooterLayout from './FooterLayout';
import { AccountUtmParams } from '@brenger/api-client';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps;

const Destination: React.FC<Props> = props => {
  React.useEffect(() => {
    props.setProgress(1);
    hotjar.fireEvent('GENERAL_FLOW_KICKOFF');
    /*
     * BRENGER_WIDGET_RENDER
     * Custom event that takes care of widget rendering
     * See event listener here: stand-alone/ts/widget.ts:65
     * */
    document.dispatchEvent(new CustomEvent('BRENGER_WIDGET_RENDER'));
    /*
     * BRENGER_WIDGET_SUBMIT
     * Custom event listener, event will pass a url that we dispatch here.
     * We optionally pass utms.
     * This is whole event is mostly done for testing purposes,
     * Step 1 (served from react app) is hardly ever seen by normal visitors, they always use the widget as step 1
     * See event fire here: stand-alone/ts/autocomplete/autocompleteForm.ts:76
     * */
    document.addEventListener('BRENGER_WIDGET_SUBMIT', (event: CustomEvent) => {
      props.push(event.detail.url);
      const pickupMeta = event.detail?.[AccountUtmParams.PICKUP];
      const deliveryMeta = event.detail?.[AccountUtmParams.DELIVERY];
      if (pickupMeta || deliveryMeta) {
        props.reset(false);
      }
      props.setDestinationMeta(pickupMeta ? { utm_account: pickupMeta } : null, StopType.PICKUP);
      props.setDestinationMeta(deliveryMeta ? { utm_account: deliveryMeta } : null, StopType.DELIVERY);
    });
    /*
     * BRENGER_AUTOCOMPLETE_SELECTED
     * Custom event listener, event will pass address details.
     * See event fire here: stand-alone/ts/autocomplete/autocompleteInput.ts:199
     * */
    document.addEventListener('BRENGER_AUTOCOMPLETE_SELECTED', (event: CustomEvent) => {
      const stopType = event.detail.name.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
      props.setDestinationDetails(event.detail.addressDetails, stopType);
      props.reset(false);
    });
  }, []);

  const submitWidget = (): void => {
    // I used to trigger a submit event on the form, but somehow Firefox and Safari ignore default behaviour
    // By triggering a click on the button, we rely on default behaviour of the button, which does work and submit fields
    const button = document.querySelector('.internal-widget form button') as HTMLButtonElement;
    if (!button) {
      return;
    }
    button.click();
  };

  const generalErrors = props.generalErrors;
  return (
    <>
      {Config.NODE_ENV === 'development' && <button onClick={() => props.reset(true)}>reset</button>}
      <SheetLayoutContentWrapper>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.headers.from_to'} />
          </Heading>
        </SheetLayoutHeader>
        <SheetLayoutContent>
          <div className="internal-widget" data-brenger-tr-widget={true}>
            <div className="a"></div>
            <div className="b"></div>
          </div>
          <div className={'item-errors'}>
            {generalErrors && generalErrors !== '' && <FlashMessage type={'error'} message={generalErrors} />}
          </div>
        </SheetLayoutContent>
      </SheetLayoutContentWrapper>
      <FooterLayout customSubmitFunction={submitWidget} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  generalErrors: state.generalTransport.error,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  reset: resetLocation => dispatch(flowActions.resetFlow(resetLocation)),
  push: path => dispatch(push(path)),
  setDestinationDetails: (details, stopType) => dispatch(flowActions.setDestinationDetails(details, stopType)),
  setDestinationMeta: (meta, stopType) => dispatch(flowActions.setDestinationMeta(meta, stopType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Destination);
