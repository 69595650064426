import React from 'react';
import { Field } from 'redux-form';

import { Translate } from 'react-localize-redux-dep-updated';
import { Heading, RfInput, SectionWrapper, SheetLayoutContent, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { hotjar } from '../../../configs/hotjar';
import { useTypedSelector } from '../../../hooks';
import { translate } from '../../../utils/localization';
import { FieldNamesProductPayment } from '../interface';
import { numbersOnly, productPaymentSchema } from '../schema';

export const ProductPaymentAmount: React.FC = () => {
  const homeSituation = useTypedSelector(state => state.generalTransport.choices?.home_situation);

  React.useEffect(() => {
    hotjar.fireEvent('product_payment');
  }, []);

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2} extraClasses={'no-margin pb-0-5'}>
          <Translate
            id={'request_flow.headers.product_payment_amount'}
            data={{
              market_place: translate(`request_flow.home_situation.fields.type.options.${homeSituation}.name`),
            }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SectionWrapper centerContent={false} sectionStyle={'primary-light'}>
        <SheetLayoutContent>
          <div className={'text--bold pb-0-5'}>
            <Translate id={'request_flow.product_payment.fields.amount.desc'} />
          </div>
          <div style={{ maxWidth: 290 }}>
            <Field
              validate={productPaymentSchema.amount}
              name={FieldNamesProductPayment.AMOUNT}
              label={translate('request_flow.product_payment.fields.amount.label')}
              type={'text'}
              component={RfInput}
              normalize={numbersOnly}
              inputMode={'numeric'}
              append={<div style={{ padding: '.1em 3px 0 3px' }}>€</div>}
            />
          </div>
        </SheetLayoutContent>
      </SectionWrapper>
      <SectionWrapper centerContent={false} sectionStyle={'white'}>
        <SheetLayoutContent>
          <b>
            <Translate id={'request_flow.product_payment.how_it_works_title'} />
          </b>
          <div
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: String(translate('request_flow.product_payment.how_it_works_text')),
            }}
          />
        </SheetLayoutContent>
      </SectionWrapper>
    </>
  );
};
