import cn from 'classnames';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useDebounce } from '../../../../hooks/useDebounce';
import InputFeedback from '../input/InputFeedback';
import InputWrapper from '../input/InputWrapper';
import './input-examples.scss';
import { InputWithProductExamplesOverlay, OverlaySpecs } from './InputWithProductExamplesOverlay';

interface Custom {
  label?: string;
}

type InputProps = WrappedFieldProps & Custom;

export const RfInputWithProductExamples: React.FC<InputProps> = ({ input, meta, label }) => {
  const inputWrapper = React.useRef<HTMLDivElement>(null);

  const [domRec, setDomRec] = React.useState<OverlaySpecs | null>(null);
  const [active, setActive] = React.useState(false);
  const debouncedRec = useDebounce<OverlaySpecs | null>(domRec, 200);
  React.useEffect(() => {
    if (meta.active) {
      setActive(true);
    }
  }, [meta.active]);

  React.useEffect(() => {
    const updateDomRec = (): void => {
      const rec = inputWrapper.current?.getBoundingClientRect();
      if (rec) {
        setDomRec({
          left: rec.left + (window.innerWidth - document.body.offsetWidth),
          width: rec.width,
          top: rec.top - document.body.scrollTop,
        });
      }
    };
    updateDomRec();
    window.addEventListener('resize', updateDomRec);
    window.addEventListener('scroll', updateDomRec);
    return () => {
      window.removeEventListener('resize', updateDomRec);
      window.removeEventListener('scroll', updateDomRec);
    };
  }, []);

  return (
    <div className={cn('input-el--examples', { 'input-el--examples--active': active })}>
      <div ref={inputWrapper}>
        <InputWrapper label={label} input={input} meta={meta} type={'text'}>
          <div className={cn('input-el--inner')}>
            <div className={'input-el--tag'}>
              <input id={input.name} className={'data-hj-whitelist'} type={'text'} autoComplete={'off'} {...input} />
              {label && (
                <label className={'input-el--label'} htmlFor={input.name}>
                  <span className={'input-el--label-text'}>{label}</span>
                </label>
              )}
            </div>
          </div>
          <InputFeedback input={input} meta={meta} error={meta.error} successMessage={false} />
        </InputWrapper>
      </div>
      {active && (
        <InputWithProductExamplesOverlay
          input={input}
          meta={meta}
          label={label}
          setActive={setActive}
          overlaySpecs={debouncedRec}
        />
      )}
    </div>
  );
};
