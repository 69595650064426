import { QuoteCreationParams } from '@brenger/api-client';
import { actionTypes } from 'redux-form';
import { call, debounce, put, select, takeLatest } from 'typed-redux-saga';
import { hotjar } from '../../../configs/hotjar';
import { ReduxFormInterAction } from '../../../typings';
import { GeneralFlowForms } from '../interface';
import { logError } from '../../../utils/basics';
import { priceClient } from '../../../utils/request';
import { actions, getPriceRequestParams } from '../ducks';
import { GeneralFlowActionTypes, SetChoiceAction, SetDestinationDetailsAction } from '../typings';

function* triggerDraft(action: ReduxFormInterAction | SetDestinationDetailsAction | SetChoiceAction): Generator {
  const formsOfInterest: string[] = [
    GeneralFlowForms.PICKUP,
    GeneralFlowForms.DELIVERY,
    GeneralFlowForms.ITEMS,
    GeneralFlowForms.PRODUCT_PAYMENT,
  ];
  const isCorrectFormChange = formsOfInterest.includes((action as ReduxFormInterAction)?.meta?.form || '');
  const isDestinationChange = action.type === GeneralFlowActionTypes.SET_DESTINATION_DETAILS;
  const isChoiceChange = action.type === GeneralFlowActionTypes.SET_CHOICE;
  if (!isCorrectFormChange && !isDestinationChange && !isChoiceChange) {
    // This change is not happening in the general flow, or further down the general flow
    return;
  }
  const params = yield* select(getPriceRequestParams);
  if (params !== null) {
    // it means we have a valid quote, so we are able to display a price to the customer
    // no need anymore to trigger draft versions
    return;
  }
  yield* put(actions.postDraftQuote());
}

function* postDraft(): Generator {
  try {
    const draftParams = (yield* select(getPriceRequestParams, { isDraft: true })) as QuoteCreationParams;
    hotjar.fireEvent('price_draft');
    yield* call(priceClient.quotes.createDraft, draftParams);
  } catch (e) {
    logError(e);
  }
}

export function* draftQuotesSaga(): Generator {
  yield* debounce(
    2500,
    [actionTypes.CHANGE, GeneralFlowActionTypes.SET_DESTINATION_DETAILS, GeneralFlowActionTypes.SET_CHOICE],
    triggerDraft
  );
  yield* takeLatest(GeneralFlowActionTypes.POST_DRAFT_QUOTES, postDraft);
}
