"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebounce = void 0;
/**
 * Copied from: https://usehooks.com/useDebounce/
 * See above link for usage examples.
 */
var React = __importStar(require("react"));
function useDebounce(value, delay) {
    // State and setters for debounced value
    var _a = React.useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    React.useEffect(function () {
        // Set debouncedValue to value (passed in) after the specified delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Return a cleanup function that will be called every time
        // useEffect is re-called. useEffect will only be re-called
        // if value changes (see the inputs array below).
        // This is how we prevent debouncedValue from changing if value is
        // changed within the delay period.
        // To put it in context, if the user is typing within our app's
        // search box, we don't want the debouncedValue to update until
        // they've stopped typing for more than 500ms.
        return function () {
            clearTimeout(handler);
        };
    }, 
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you
    // need to be able to change that dynamically.
    [value, delay]);
    return debouncedValue;
}
exports.useDebounce = useDebounce;
