import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Button, Col, Container, Heading, IconArrow, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { getActiveLanguageSettings } from '../../../utils/localization';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { r } from '../../../routes';

export const Failed: React.FC<{ trId?: string }> = ({ trId }) => {
  const dispatch = useDispatch();
  return (
    <Container>
      <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            <Heading size={5}>
              <Translate id={'request_flow.thank_you.payment_failed_heading_opp'} />
            </Heading>
            <p>
              <Translate
                id={'request_flow.thank_you.payment_failed_text_opp'}
                data={{ phonenumber: getActiveLanguageSettings().contact.phone.customer }}
              />
            </p>
          </Col>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Button
              /**
               * OPP payments are the only ones that can fail now
               */
              onClick={() => dispatch(push(r.generalFlow.oppPayment({ id: trId })))}
              buttonStyle={'secondary'}
              iconPos={'right'}
              fullWidth={true}
            >
              <Translate id={'request_flow.thank_you.pay_again_button'} />
              <IconArrow />
            </Button>
          </Col>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Button
              /**
               * OPP payments are the only ones that can fail now
               */
              onClick={() => window.location.reload()}
              buttonStyle={'outline'}
              iconPos={'right'}
              fullWidth={true}
            >
              <Translate id={'request_flow.thank_you.payment_failed_verify_again'} />
              <IconArrow />
            </Button>
          </Col>
        </Row>
      </SectionWrapper>
    </Container>
  );
};
