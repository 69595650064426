import { fontLoader } from '@brenger/react';
import { ConnectedRouter, push } from 'connected-react-router';
import { History } from 'history';
import React, { Component } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Route, RouteProps, Switch } from 'react-router'; // yes, react-router and not react-router-dom, otherwise it will not work properly with connected-react-router
import { Dispatch } from 'redux';
import { AppLoader } from './brenger-shared-ui';
import './brenger-shared-ui/assets/style.scss';
import { Config } from './config';
import { localization } from './configs/localization';
import { ApiSettings } from './modules/ApiSettings';
import { GeneralFlow } from './modules/GeneralFlow';
import { CanceledPayment } from './modules/Payment/CanceledPage';
import { ThankYouPage } from './modules/Payment/ThankYouPage';
import { PsTool } from './modules/Tools/PsTool';
import FailedSocialAuth from './modules/User/components/FailedSocialAuth';
import VerifySocialAuth from './modules/User/components/VerifySocialAuth';
import Dashboard from './modules/User/containers/Dashboard';
import Invoices from './modules/User/containers/Invoices';
import Login from './modules/User/containers/Login';
import PasswordNew from './modules/User/containers/PasswordNew';
import PasswordReset from './modules/User/containers/PasswordReset';
import Register from './modules/User/containers/Register';
import { r } from './routes';
import { actions as baseActions } from './state/ducks/baseReducer';
import { RootState } from './typings';
import { getCrossDomainSetup, pushToDataLayer } from './utils/basics';
import { getActiveLanguageSettings } from './utils/localization';
import BusinessFlow from './modules/BusinessFlow';

interface OwnProps {
  history: History;
}

type ReduxProps = LocalizeContextProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class App extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    localization.configure(props);
  }

  public componentDidMount(): void {
    fontLoader();
    this.props.startLoading();
    if (Config.TRIGGER_CONSENT_GTM) {
      pushToDataLayer({ event: 'cookie consent' });
    }
    // This is the menu interpreter, @TODO: should this be here?
    document.addEventListener('click', event => {
      const target = event.target as HTMLElement;
      const classOfTarget = target.getAttribute('class') || '';
      if (classOfTarget.indexOf('brenger-menu-link') === -1) {
        return;
      }
      const href = (event.target as HTMLLinkElement)?.href;
      if (!href) {
        return;
      }
      const url = new URL(href);
      if (url.pathname.indexOf('/customer') > -1) {
        event.preventDefault();
        this.props.push(url.pathname.replace(`${getActiveLanguageSettings().labels.contentSite}/customer/`, ''));
        return;
      }
    });
  }

  public render(): JSX.Element {
    const crossDomain = getCrossDomainSetup();
    /** Cross Domain
     * If we are serving the app cross domain, we only want to support general flow routes
     * the reason behind this is that at the moment we don't support shared sessions cross domain.
     * So we support just the general flow, and thats it.
     * */
    const activeRoutes = crossDomain ? generalFlowRoutes : routes;
    return (
      <div className="app-main-wrapper">
        {!this.props.loading && (
          <ConnectedRouter history={this.props.history}>
            <Switch>
              {activeRoutes.map((route, idx) => (
                <Route key={idx} {...route} />
              ))}
            </Switch>
          </ConnectedRouter>
        )}
        {this.props.loading && <AppLoader />}
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  push: path => dispatch(push(path)),
  startLoading: () => dispatch(baseActions.startLoading()),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  loading: state.base.loading,
  history: ownProps.history,
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));

const generalFlowRoutes: { path: string | string[]; component: RouteProps['component']; exact: boolean }[] = [
  {
    path: r.payment.thankYou({}),
    component: ThankYouPage,
    exact: true,
  },
  {
    path: r.payment.canceled(),
    component: CanceledPayment,
    exact: true,
  },
  {
    path: r.generalFlow.index(),
    component: GeneralFlow,
    exact: false,
  },
];

const routes: { path: string | string[]; component: RouteProps['component']; exact: boolean }[] = [
  ...generalFlowRoutes,
  {
    path: r.user.login(),
    component: Login,
    exact: true,
  },
  {
    path: r.user.register(),
    component: Register,
    exact: true,
  },
  {
    path: r.user.passwordReset(),
    component: PasswordReset,
    exact: true,
  },
  {
    path: r.user.passwordToken({}),
    component: PasswordNew,
    exact: true,
  },
  {
    path: r.user.loginCheck(),
    component: VerifySocialAuth,
    exact: true,
  },
  {
    path: r.user.loginError(),
    component: FailedSocialAuth,
    exact: true,
  },
  {
    path: r.user.dashboard(),
    component: Dashboard,
    exact: true,
  },
  {
    path: r.user.invoices(),
    component: Invoices,
    exact: true,
  },
  {
    path: r.businessFlow.index(),
    component: BusinessFlow,
    exact: false,
  },
  {
    path: r.user.apiSettings(),
    component: ApiSettings,
    exact: false,
  },
  {
    path: r.tools.productSelection(),
    component: PsTool,
    exact: true,
  },
];
