import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ThankYouDataParams, useThankYouData, useTypedSelector } from '../../../hooks';
import { Failed } from '../FailedPage';
import { Loading } from './components/Loading';
import { Success } from './components/Success';
import { getLocation } from 'connected-react-router';
import { Canceled } from './components/Canceled';

type Props = RouteComponentProps<ThankYouDataParams>;

export const ThankYouPage: React.FC<Props> = props => {
  const { isLoading, error, trId, ...rest } = useThankYouData(props.match.params);
  const location = useTypedSelector(getLocation);
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Failed trId={trId} />;
  }
  if (location.search.includes('payment_status=canceled')) {
    return <Canceled id={trId} type={rest.type} />;
  }

  return <Success trId={trId} {...rest} />;
};
