import * as React from 'react';
import './modal.scss';
import { IconCross } from '../../basics/icons';
import cn from 'classnames';

type Style = 'basic' | 'blue' | 'chat' | 'status-page' | 'white';
type Size = 'small';

interface Props {
  open?: boolean;
  header?: React.ReactNode;
  onlyToggleByButton?: boolean;
  onClick?: React.MouseEventHandler;
  style?: Style;
  size?: Size;
  noShade?: boolean;
  innerId?: string;
}

export const Modal: React.FC<Props> = ({
  open = false,
  header,
  onlyToggleByButton = false,
  onClick,
  style = 'basic',
  size = 'normal',
  noShade = false,
  innerId,
  children,
}) => {
  return (
    /*
     * Was thinking of adding the dialog layer here, but couldn't because of the modal usage in track and trace page.
     * There is no need for now to restructure, so left it like this.
     */
    <div
      className={cn('modal', 'modal--style-' + style, 'modal--style-' + size, {
        'modal--isOpen': open,
        'modal--isClosed': !open,
      })}
    >
      <div
        className={'modal-backdrop' + (noShade ? ' modal-backdrop--no-shade' : '')}
        onClick={onlyToggleByButton ? undefined : onClick}
      >
        <div id={innerId} className={'modal-inner'} onClick={blockClick}>
          <div className={'modal-header'} style={{ paddingRight: onlyToggleByButton ? '0' : undefined }}>
            {header && header}
            {!onlyToggleByButton && (
              <div className={'modal-close trigger'} onClick={onClick}>
                <IconCross />
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;

const blockClick: React.MouseEventHandler = event => event.stopPropagation();
